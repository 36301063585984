import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import { ActionBar, Alert, Button, Modal, View, useToast } from "reshaped"

import { modalsStore } from "#modals"
import { organizationsPageStore } from "../../pages/Organization/page.model"

export const DeleteMemberModal = observer(() => {
	const deleteMemberModalStore = modalsStore.deleteMember
	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const deleteMember = async () => {
		if (await deleteMemberModalStore.deleteMember()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Member deleted.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (deleteMemberModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={deleteMemberModalStore.isOpen}
			padding={0}
			size="410px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>
					Remove {deleteMemberModalStore.memberName}?
				</Modal.Title>
				<Modal.Subtitle>
					Are you sure you want to remove{" "}
					{deleteMemberModalStore.memberName} from{" "}
					{organizationsPageStore.organization?.name}? This action
					cannot be reversed.
				</Modal.Subtitle>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={deleteMemberModalStore.isDeleting}
						onClick={() => {
							deleteMemberModalStore.closeModal()
						}}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={deleteMemberModalStore.isDeleting}
						onClick={deleteMember}
					>
						Remove User
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
