import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

export const InviteToOrganizationModalModel = types
	.model("InviteToOrganizationModal", {
		isOpen: types.optional(types.boolean, false),
		isCreating: types.optional(types.boolean, false),
		organizationId: types.optional(types.string, ""),
		form: types.optional(
			types
				.model("Form", {
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => ({
					setError(hasError: boolean, message = "") {
						self.hasError = hasError
						self.errorMessage = message
					},
				})),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (organizationId: string) => {
			Fathom.trackEvent("Organization:Invite Started")

			self.organizationId = organizationId
			self.isOpen = true
			self.form.setError(false)
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const inviteToOrganization = flow(function* (
			emails: { email: string }[],
			organizationId: string,
		) {
			if (emails.length === 0) {
				self.form.hasError = true
				self.form.errorMessage = "Please add at least one valid email"
				return false
			}

			try {
				self.isCreating = true

				yield Promise.all([
					api.createInvites(
						{ invites: emails },
						{
							queries: { organization_id: organizationId },
						},
					),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				Fathom.trackEvent("Organization:Invite Success")

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))
				organizationsPageStore.getPendingInvites()

				self.isCreating = false

				return true
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, inviteToOrganization }
	})
