import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { appStore } from "#app"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: () => Promise<void>

export const TransferOwnershipModalModel = types
	.model("TransferOwnership", {
		isOpen: types.optional(types.boolean, false),
		isLoadingTransferOwnership: types.optional(types.boolean, false),
		organizationId: types.optional(types.string, ""),
		memberId: types.optional(types.string, ""),
		memberName: types.optional(types.string, ""),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			organizationId: string,
			memberId: string,
			memberName: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Organization:Delete Started")
			updateCallback = callback

			self.organizationId = organizationId
			self.memberId = memberId
			self.memberName = memberName

			self.isOpen = true
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const transferOwnership = flow(function* () {
			try {
				self.isLoadingTransferOwnership = true

				yield Promise.all([
					api.patchMember(
						{
							role: "admin",
						},
						{
							params: { member_id: self.memberId },
							queries: { organization_id: self.organizationId },
						},
					),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				yield Promise.all([
					api.patchMember(
						{
							role: "member",
						},
						{
							params: {
								member_id: appStore.auth.isAuthenticated
									? appStore.auth.profile.id
									: "",
							},
							queries: { organization_id: self.organizationId },
						},
					),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				Fathom.trackEvent("Organization:Transfer ownership Success")

				yield new Promise((resolve) => setTimeout(resolve, 200))
				organizationsPageStore.getOrganizationsPage({
					page: 1,
				})
				organizationsPageStore.getMembers()
				closeModal()

				self.isLoadingTransferOwnership = false

				return true
			} catch (error) {
				console.error("Failed to transfer ownership:", error)
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, transferOwnership }
	})
