import mixpanel from "mixpanel-browser"
import type { Instance } from "mobx-state-tree"
import type { ProfileModel } from "#api"
import { appStore } from "#app"
import eventCatalog from "./eventCatalog"
import { type Contexts, type EventGroup, dictionary } from "./eventDictionary"
import type { Event } from "./eventDictionary"

export type { EventGroup, Contexts }

const isDev =
	import.meta.env.VITE_APP_ENV === "development" ||
	import.meta.env.VITE_APP_ENV === "staging"

// create an instance of the Mixpanel object
mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
	persistence: "localStorage",
	debug: isDev || false,
	// @ts-expect-error FIXME: explore why autocapture is not typed on Mixpanel
	autocapture: false,
	track_pageview: "url-with-path",
})

// disable debug log after initialization
mixpanel.set_config({
	debug: false,
})

const getNestedProfileProp = (propPath: string): string => {
	try {
		if (!appStore.auth.isAuthenticated) return "unknown"

		const props = propPath.split(".")

		if (
			!appStore.auth.profile ||
			typeof appStore.auth.profile !== "object"
		) {
			return "unknown"
		}

		let current: Instance<typeof ProfileModel> = appStore.auth.profile

		for (const prop of props) {
			if (current && typeof current === "object" && prop in current) {
				current = current[prop as keyof typeof current]
			} else {
				return "unknown"
			}
		}

		return current?.toString() ?? "unknown"
	} catch (error) {
		console.error(error)
		return "unknown"
	}
}

export const track = ({
	event,
	context,
	properties,
}: {
	event: Event
	context: unknown
	properties?: Record<string, unknown>
}) => {
	try {
		if (event.name in eventCatalog) {
			const organizationId = getNestedProfileProp("home_organization.id")
			const profileId = getNestedProfileProp("id")
			const email = getNestedProfileProp("email")

			const eventProperties = {
				event: event.name,
				context: context,
				organizationId: organizationId,
				profileId: profileId,
				email: email,
				...properties,
			}

			mixpanel.track(event.name, eventProperties)
			if (isDev) {
				console.log(eventProperties)
			}
		} else {
			console.warn(
				`Event ${event} not found in event catalog or not enabled`,
			)
		}
	} catch (error) {
		console.error(error)
	}
}

export const getContext = (eventKey: string, contextStr: string) => {
	try {
		// Iterate through all events in dictionary
		for (const [_, event] of Object.entries(dictionary)) {
			// Check if this is the event we're looking for and it has contexts
			if (event.key === eventKey && event.contexts) {
				// Look through contexts for exact or partial match
				for (const [contextKey, contextValue] of Object.entries(
					event.contexts,
				)) {
					if (
						contextValue === contextStr ||
						contextValue
							.toLowerCase()
							.includes(contextStr.toLowerCase()) ||
						contextStr
							.toLowerCase()
							.includes(contextValue.toLowerCase())
					) {
						return contextValue
					}
				}
			}
		}

		return contextStr // Return original if no match found
	} catch (error) {
		console.error(error)
	}
}

export { dictionary } from "./eventDictionary"
