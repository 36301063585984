export type Contexts = Record<string, string>

export type Event = {
	name: string
	key: string
	contexts?: Contexts
}

export type EventGroup = {
	[key: string]: Event
}

export const dictionary: EventGroup = {
	pageView: {
		name: "Page View",
		key: "pageView",
		contexts: {
			home: "Home",
			pipelines: "Pipelines",
			pipelineDetail: "Pipeline Detail",
			demoPipelineDetail: "Demo Pipeline Detail",
			spaces: "Spaces",
			pipelineCreate: "Pipeline Create",
			pipelineCreateEmailEncryption: "Pipeline Create Email Encryption",
			unauthenticated: "Unauthenticated",
			notFound: "Page not found",
			examples: "Examples",
		},
	},
	modalView: {
		name: "Modal View",
		key: "modalView",
		contexts: {
			profile: "Profile",
		},
	},
	onboardingJourney: {
		name: "Onboarding Journey",
		key: "onboardingJourney",
		contexts: {
			started: "Started",
			skipped: "Skipped",
			completed: "Completed",
			failed: "Failed",
		},
	},
	onboardingStepRole: {
		name: "Onboarding Step Role",
		key: "onboardingStepRole",
		contexts: {
			dataEngineer: "Data Engineer",
			dataAnalyst: "Data Analyst",
			dataScientist: "Data Scientist",
			dataConsultant: "Data Consultant",
			dataLeadManager: "Data Lead / Manager",
			founder: "Founder",
			other: "Other",
		},
	},
	onboardingStepUseCase: {
		name: "Onboarding Step Use Case",
		key: "onboardingStepUseCase",
		contexts: {
			eventDrivenAnalytics: "Event Driven Analytics",
			aiParsing: "AI Parsing",
			ragPipeline: "RAG Pipeline",
			automation: "Automation",
			dataEnrichment: "Data Enrichment",
			realTimeApiCall: "Real Time API Call",
			other: "Other",
			no: "No",
		},
	},
	onboardingStepPreviousExperience: {
		name: "Onboarding Step Previous Experience",
		key: "onboardingStepPreviousExperience",
		contexts: {
			triedBefore: "Tried Before",
			activelyUsing: "Actively Using",
			expert: "Expert",
			no: "No",
		},
	},
	pipelineActionCreate: {
		name: "Pipeline Create",
		key: "pipelineActionCreate",
		contexts: {
			newPipeline: "New Pipeline",
			emailEncryption: "Email Encryption", // NOTE: this cannot be tracked as event
			jupyter: "Jupyter",
			completed: "Completed",
			cancelled: "Cancelled",
		},
	},
	pipelineActionDelete: {
		name: "Pipeline Delete",
		key: "pipelineActionDelete",
		contexts: {
			initiated: "Initiated",
			completed: "Completed",
			cancelled: "Cancelled",
		},
	},
	pipelineActionRename: {
		name: "Pipeline Rename",
		key: "pipelineActionRename",
		contexts: {
			initiated: "Initiated",
			completed: "Completed",
			cancelled: "Cancelled",
		},
	},
	pipelineActionPause: {
		name: "Pipeline Pause",
		key: "pipelineActionPause",
		contexts: {
			paused: "Paused",
		},
	},
	pipelineActionResume: {
		name: "Pipeline Resume",
		key: "pipelineActionResume",
		contexts: {
			resumed: "Resumed",
		},
	},
	pipelineConnectorTabs: {
		name: "Pipeline Connector Tabs",
		key: "pipelineConnectorTabs",
		contexts: {
			source: "Source",
			transform: "Transform",
			sink: "Sink",
		},
	},
	pipelineActionEdited: {
		name: "Pipeline Edited",
		key: "pipelineActionEdited",
		contexts: {
			source: "Source",
			transform: "Transform",
			sink: "Sink",
		},
	},
	pipelineActionDeploy: {
		name: "Pipeline Deploy",
		key: "pipelineActionDeploy",
		contexts: {
			deployed: "Deployed",
		},
	},
	// not implemented
	pipelineDetailsTabs: {
		name: "Pipeline Details Tabs",
		key: "pipelineDetailsTabs",
		contexts: {
			details: "Details",
			accessTokens: "Access Tokens",
			logs: "Logs",
			setupPythonSdk: "Setup Python SDK",
		},
	},
	spacesActionCreate: {
		name: "Space Create",
		key: "spacesActionCreate",
		contexts: {
			initiated: "Initiated",
			cancelled: "Cancelled",
			completed: "Completed",
		},
	},
	spacesActionDelete: {
		name: "Space Delete",
		key: "spacesActionDelete",
		contexts: {
			initiated: "Initiated",
			cancelled: "Cancelled",
			completed: "Completed",
		},
	},
	spacesActionRename: {
		name: "Space Rename",
		key: "spacesActionRename",
		contexts: {
			initiated: "Initiated",
			cancelled: "Cancelled",
			completed: "Completed",
		},
	},
	profileInteraction: {
		name: "Profile Interaction",
		key: "profileInteraction",
		contexts: {
			profile: "Profile",
			examples: "Examples",
			documentation: "Documentation",
			joinSlack: "Join Slack",
			sendFeedback: "Send Feedback",
			cookiePreferences: "Cookie Preferences",
			privacyPolicy: "Privacy Policy",
			logout: "Logout",
		},
	},
}
