import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: () => Promise<void>

export const DeletePipelineModalModel = types
	.model("DeletePipelineModal", {
		isOpen: types.optional(types.boolean, false),
		isDeleting: types.optional(types.boolean, false),
		pipelineId: types.optional(types.string, ""),
		pipelineName: types.optional(types.string, ""),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			pipelineId: string,
			pipelineName: string,
			callback: () => Promise<void>,
		) => {
			Fathom.trackEvent("Pipeline:Delete Started")
			updateCallback = callback

			self.pipelineId = pipelineId
			self.pipelineName = pipelineName

			self.isOpen = true
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const deletePipeline = flow(function* () {
			try {
				self.isDeleting = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.deletePipeline(undefined, {
						params: { pipeline_id: self.pipelineId },
						queries: {
							organization_id:
								localStorage.getItem("activeOrganization") ||
								organizationsPageStore.organization?.id,
						},
					}),
				])

				Fathom.trackEvent("Pipeline:Delete Success")

				try {
					yield updateCallback()
				} catch (error) {}

				self.isDeleting = false

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isDeleting = false

				return true
			} catch (error) {
				self.isDeleting = false
				self.error = error

				return false
			}
		})

		return {
			openModal,
			closeModal,
			deletePipeline,
		}
	})
