import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"
import { router } from "../../router"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: () => Promise<void>

export const DeleteMemberModalModel = types
	.model("DeletionMember", {
		isOpen: types.optional(types.boolean, false),
		isDeleting: types.optional(types.boolean, false),
		organizationId: types.optional(types.string, ""),
		memberId: types.optional(types.string, ""),
		memberName: types.optional(types.string, ""),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			organizationId: string,
			memberId: string,
			memberName: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Organization:Member Delete Started")
			updateCallback = callback

			self.organizationId = organizationId
			self.memberId = memberId
			self.memberName = memberName

			self.isOpen = true
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const deleteMember = flow(function* () {
			try {
				self.isDeleting = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.deleteMember(undefined, {
						params: { member_id: self.memberId },
						queries: { organization_id: self.organizationId },
					}),
				])

				Fathom.trackEvent("Organization:Member Delete Success")

				try {
					yield updateCallback()
				} catch (error) {}

				self.isDeleting = false

				closeModal()
				organizationsPageStore.getMembers()
				router.push("OrganizationPage")

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isDeleting = false

				return true
			} catch (error) {
				self.isDeleting = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, deleteMember }
	})
