import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import { ActionBar, Alert, Button, Modal, View, useToast } from "reshaped"

import { modalsStore } from "#modals"

export const LeaveAsNonOwnerModal = observer(() => {
	const leaveAsNonOwnerModalStore = modalsStore.leaveAsNonOwner
	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const leaveOrganization = async () => {
		if (await leaveAsNonOwnerModalStore.leaveOrganization()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Organization leaved.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (leaveAsNonOwnerModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={leaveAsNonOwnerModalStore.isOpen}
			padding={0}
			size="410px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Leave Acme Corporation</Modal.Title>
				<Modal.Subtitle>
					Are you sure you want to leave this organization?
				</Modal.Subtitle>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={leaveAsNonOwnerModalStore.isLeaving}
						onClick={() => {
							leaveAsNonOwnerModalStore.closeModal()
						}}
					>
						Discard
					</Button>
					<Button
						color="critical"
						loading={leaveAsNonOwnerModalStore.isLeaving}
						onClick={leaveOrganization}
					>
						Leave Team
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
