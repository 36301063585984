import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import { ActionBar, Alert, Button, Modal, View, useToast } from "reshaped"

import { isApiError } from "#api"
import { modalsStore } from "#modals"
import { DeleteOrganizationModalModel } from "./modal.model"

export const DeleteOrganizationModal = observer(() => {
	const deleteOrganizationModalStore = modalsStore.deleteOrganization
	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const deleteOrganization = async () => {
		if (await deleteOrganizationModalStore.deleteOrganization()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: `"${deleteOrganizationModalStore.organizationName}" deleted.`,
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (deleteOrganizationModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title={
					deleteOrganizationModalStore.error.status === 405
						? "You cannot delete your home organization."
						: "Something went wrong. Please try again."
				}
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={deleteOrganizationModalStore.isOpen}
			padding={0}
			size="410px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>
					Delete {deleteOrganizationModalStore.organizationName}
				</Modal.Title>
				<Modal.Subtitle>
					Are you sure you want to delete this organization? You will
					loose all pipelines. This action cannot be reversed.
				</Modal.Subtitle>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={deleteOrganizationModalStore.isDeleting}
						onClick={() => {
							deleteOrganizationModalStore.closeModal()
						}}
					>
						Discard
					</Button>
					<Button
						color="critical"
						loading={deleteOrganizationModalStore.isDeleting}
						onClick={() => {
							deleteOrganization()
						}}
					>
						Delete Organization
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
