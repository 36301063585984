import { cast, flow, types } from "mobx-state-tree"

import { MemberModel, api } from "#api"

export const MemberPage = types
	.model({
		member: types.maybeNull(MemberModel),
		isFetchingMember: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const getMember = flow(function* (
			memberId: string,
			organizationId: string,
		) {
			self.isFetchingMember = true

			try {
				const member = yield api.getMember({
					params: {
						member_id: memberId,
					},
					queries: {
						organization_id: organizationId,
					},
				})

				self.member = cast(member)
			} catch (error) {
				console.error("Failed to fetch member:", error)
			} finally {
				self.isFetchingMember = false
			}
		})

		return {
			getMember,
		}
	})

export const memberPageStore = MemberPage.create()
