import { AnimatePresence, m } from "framer-motion"
import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { ChevronDown, Edit2, Pause, Play, Trash } from "react-feather"
import {
	Badge,
	Button,
	DropdownMenu,
	HiddenVisually,
	Skeleton,
	Tabs,
	Text,
	View,
} from "reshaped"
import { P, match } from "ts-pattern"

import { AceEditor } from "#components/AceEditor"
import { modalsStore } from "#modals"
import { router } from "#router"

import { demoPipelineDetailPageStore } from "./page.model"

import { useEffect } from "preact/hooks"
import { dictionary, track } from "#analytics/eventManager.ts"
import { ExpandableTab } from "../../components/PipelineCreation/ExpandableTab/component"
import { registerCreatePipelineFunction } from "../../utils"
import { AccessTokens } from "./components/AccessTokens"
import { DataGenerator } from "./components/DataGenerator"
import { GlassFlowSink } from "./components/GlassFlowSink"
import { Logs } from "./components/Logs"
import css from "./page.module.css"
const Header = observer(() => {
	const store = demoPipelineDetailPageStore

	return (
		<View direction="column">
			<View
				direction="row"
				gap={1}
				align="center"
				justify="space-between"
			>
				<View direction="row" gap={2} align="center">
					{store.pipeline ? (
						<>
							<Text variant="title-2" weight="medium">
								{store.pipeline.name}
							</Text>
							<View className={css.demoBadge}>
								<Badge color="neutral" variant="faded" rounded>
									Demo
								</Badge>
							</View>
							{store.pipeline.isRunning ? (
								<Badge color="positive" variant="faded" rounded>
									Active
								</Badge>
							) : (
								<Badge color="neutral" variant="faded" rounded>
									Paused
								</Badge>
							)}
						</>
					) : (
						<View paddingTop={2}>
							<Skeleton width={50} height={9} />
						</View>
					)}
				</View>
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => (
							<Button
								color="neutral"
								variant="solid"
								endIcon={ChevronDown}
								attributes={{
									...attributes,
									style: { borderRadius: "8px" },
								}}
								disabled={!store.pipeline}
							>
								Actions
							</Button>
						)}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							{store.pipeline?.isRunning ? (
								<DropdownMenu.Item
									icon={Pause}
									color="neutral"
									onClick={() => {
										let pipeline = store.pipeline
										if (pipeline) {
											pipeline.pause()
										}
									}}
								>
									Pause
								</DropdownMenu.Item>
							) : (
								<DropdownMenu.Item
									icon={Play}
									color="neutral"
									onClick={() => {
										let pipeline = store.pipeline
										if (pipeline) {
											pipeline.resume()
										}
									}}
								>
									Resume
								</DropdownMenu.Item>
							)}
							<DropdownMenu.Item
								icon={Edit2}
								onClick={() => {
									let pipeline = store.pipeline
									if (pipeline) {
										modalsStore.renamePipeline.openModal(
											pipeline.id,
											pipeline.name,
											async () => {
												store.refreshPipeline()
											},
										)
									}
								}}
							>
								Rename
							</DropdownMenu.Item>
						</DropdownMenu.Section>

						<DropdownMenu.Section>
							<DropdownMenu.Item
								color="critical"
								icon={Trash}
								onClick={() => {
									let pipeline = store.pipeline
									if (pipeline) {
										modalsStore.deletePipeline.openModal(
											pipeline.id,
											pipeline.name,
											async () => {
												router.push("PipelinesList")
											},
										)
									}
								}}
							>
								Delete
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>
			</View>
		</View>
	)
})

export const DemoPipelineDetail = observer(() => {
	const store = demoPipelineDetailPageStore
	const routes = router.useRoute([
		"DemoPipelinesDetailDataGenerator",
		"DemoPipelinesDetailGlassFlowSink",
		"DemoPipelinesDetailTokens",
		"DemoPipelinesDetailLogs",
	])
	registerCreatePipelineFunction("showedYourFirstPipeline", () =>
		store.onboardingTips.setOnboardingTip("yourFirstPipeline", true),
	)
	registerCreatePipelineFunction("showedMergeEmail", () =>
		store.onboardingTips.setOnboardingTip("mergeEmail", true),
	)
	registerCreatePipelineFunction("showedSeeAppliedChanges", () =>
		store.onboardingTips.setOnboardingTip("seeAppliedChanges", true),
	)
	registerCreatePipelineFunction("showedClickAddGenerator", () =>
		store.onboardingTips.setOnboardingTip("clickAddGenerator", true),
	)
	registerCreatePipelineFunction("showedSelectValueName", () =>
		store.onboardingTips.setOnboardingTip("selectValueName", true),
	)
	registerCreatePipelineFunction("showedDataWithNewSchema", () =>
		store.onboardingTips.setOnboardingTip("dataWithNewSchema", true),
	)
	registerCreatePipelineFunction("showedLogsUpdates", () =>
		store.onboardingTips.setOnboardingTip("logsUpdates", true),
	)
	registerCreatePipelineFunction("showedSourceTip", () => {
		store.onboardingTips.setOnboardingTip("source", true)
	})
	registerCreatePipelineFunction("showedSinkTip", () => {
		store.onboardingTips.setOnboardingTip("sink", true)
	})

	useEffect(() => {
		track({
			event: dictionary.pageView,
			context:
				dictionary?.pageView?.contexts?.demoPipelineDetail ??
				"Demo Pipeline Detail",
		})
	}, [])

	useEffect(() => {
		store.onboardingTips.loadFromLocalStorage(store.pipeline?.id)

		store.pipeline?.id &&
			modalsStore.onboardingTip.openModal(
				"Great job! You created the first pipeline successfully!<br /><br />Now you can change the code in the <b>Transform</b> sections and explore real-time changes in the <b>GlassFlow Sink</b> and <b>Logs</b> tabs.",
				"545px",
				"showedYourFirstPipeline",
				store.onboardingTips.yourFirstPipeline,
				false,
				false,
				"transform",
			)
	}, [
		store.pipeline?.id,
		store.onboardingTips.yourFirstPipeline,
		store.onboardingTips.loadFromLocalStorage,
	])

	const selectedTab = match(routes)
		.with(router.P.DemoPipelinesDetailTokens(P._), () => "tokens")

		.with(
			router.P.DemoPipelinesDetailDataGenerator(P._),
			() => "dataGenerator",
		)
		.with(router.P.DemoPipelinesDetailLogs(P._), () => "logs")
		.otherwise(() => "glassFlowSink")

	return (
		<View gap={4} paddingTop={4}>
			<Header />

			{/* Trigger the loading of the lazy Ace Chunk when we enter the page, not just when the transformation tab is shown. */}
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{/* End of Trigger */}
			<View gap={3} direction="row" align="stretch">
				<View.Item columns={4}>
					<ExpandableTab
						name="Dummy Data Generator"
						label="Source"
						onClick={() => {
							modalsStore.demoPipelineSource.openModal()
							setTimeout(
								() =>
									modalsStore.onboardingTip.openModal(
										"You can connect your data source here while creating a pipeline.<br/>In this demo, we will use the Dummy Data Generator for the data source.",
										"545px",
										"showedSourceTip",
										store.onboardingTips.source,
										true,
										true,
									),
								200,
							)
						}}
					/>
				</View.Item>
				<View.Item columns={4}>
					<ExpandableTab
						name="E-mail Encryption"
						label="Transform"
						id="transform"
						onClick={() => {
							setTimeout(
								() =>
									modalsStore.demoPipelineTransform.openModal(),
								!store.onboardingTips.mergeEmail ? 300 : 0,
							)
							setTimeout(
								() =>
									modalsStore.onboardingTip.openModal(
										"You can start with merging “email” and “encrypted email” data. <br/>Then, click <b>Save Changes</b> to display real-time changes.",
										"487px",
										"showedMergeEmail",
										store.onboardingTips.mergeEmail,
										true,
										true,
									),
								500,
							)
						}}
					/>
				</View.Item>
				<View.Item columns={4}>
					<ExpandableTab
						name="GlassFlow Sink"
						label="Sink"
						onClick={() => {
							modalsStore.demoPipelineSink.openModal()
							setTimeout(
								() =>
									modalsStore.onboardingTip.openModal(
										"You can connect your data sink to the desired destination here.<br/>In this demo, it is only possible to preview data in GlassFlow.",
										"484px",
										"showedSinkTip",
										store.onboardingTips.sink,
										true,
										true,
									),
								200,
							)
						}}
					/>
				</View.Item>
			</View>
			<View gap={3}>
				<Tabs
					defaultValue={selectedTab}
					onChange={(event) => {
						if (store.pipeline) {
							let routeParameters = {
								pipelineId: store.pipeline.id,
								spaceId: store.pipeline.space_id,
							}

							match(event.value)
								.with("dataGenerator", () => {
									history.pushState(
										{},
										"",
										router.DemoPipelinesDetailDataGenerator(
											routeParameters,
										),
									)
									setTimeout(
										() =>
											modalsStore.onboardingTip.openModal(
												"Click <b>Add Generator</b> to generate different types of data.",
												"460px",
												"showedClickAddGenerator",
												store.onboardingTips
													.clickAddGenerator,
												true,
												true,
											),
										700,
									)
								})
								.with("tokens", () => {
									history.pushState(
										{},
										"",
										router.DemoPipelinesDetailTokens(
											routeParameters,
										),
									)
								})
								.with("logs", () => {
									history.pushState(
										{},
										"",
										router.DemoPipelinesDetailLogs(
											routeParameters,
										),
									)
									store.onboardingTips.seeAppliedChanges &&
										setTimeout(
											() =>
												modalsStore.onboardingTip.openModal(
													"Here you can follow all updates about the pipeline. As you updated the transform function, the system created a new log entry.",
													"510px",
													"showedLogsUpdates",
													store.onboardingTips
														.logsUpdates,
													true,
													true,
												),
											500,
										)
								})
								.otherwise(() => {
									history.pushState(
										{},
										"",
										router.DemoPipelinesDetailGlassFlowSink(
											routeParameters,
										),
									)
									store.dataGenerator.form.hasNewGenerators &&
										store.onboardingTips.selectValueName &&
										setTimeout(
											() =>
												modalsStore.onboardingTip.openModal(
													"You can see the data generated with the new schema in the sink view. <br/><br/>Now, go to the <b>Logs</b> tab.",
													"529px",
													"showedDataWithNewSchema",
													store.onboardingTips
														.dataWithNewSchema,
													true,
													true,
												),
											500,
										)
								})
						}
					}}
				>
					<Tabs.List>
						<Tabs.Item value="dataGenerator">
							<View paddingInline={2}>Data Generator</View>
						</Tabs.Item>
						<div id="glassFlowSink">
							<Tabs.Item value="glassFlowSink">
								<View paddingInline={2}>GlassFlow Sink</View>
							</Tabs.Item>
						</div>
						<Tabs.Item value="tokens">
							<View paddingInline={2}>Access Tokens</View>
						</Tabs.Item>
						<Tabs.Item value="logs">
							<View paddingInline={2}>Logs</View>
						</Tabs.Item>
					</Tabs.List>
					<View paddingBlock={4}>
						<AnimatePresence mode="wait">
							<Tabs.Panel
								value="dataGenerator"
								key="dataGeneratorParent"
							>
								<TabPanel key="dataGenerator">
									<DataGenerator />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel
								value="glassFlowSink"
								key="glassFlowSinkParent"
							>
								<TabPanel key="glassFlowSink">
									<GlassFlowSink />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel value="tokens" key="tokensParent">
								<TabPanel key="tokens">
									<AccessTokens />
								</TabPanel>
							</Tabs.Panel>
							<Tabs.Panel value="logs" key="logsParent">
								<TabPanel key="logs">
									<Logs />
								</TabPanel>
							</Tabs.Panel>
						</AnimatePresence>
					</View>
				</Tabs>
			</View>
		</View>
	)
})

export const TabPanel: React.FunctionComponent<{ key: string }> = observer(
	(props) => {
		return (
			<m.div
				key={props.key}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.4 }}
			>
				{props.children}
			</m.div>
		)
	},
)
