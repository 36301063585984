import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: () => Promise<void>

export const CreateAccessTokenModalModel = types
	.model("CreateAccessTokenModal", {
		pipelineId: types.maybe(types.string),

		isOpen: types.optional(types.boolean, false),
		isCreating: types.optional(types.boolean, false),
		form: types.optional(
			types
				.model("Form", {
					name: types.optional(types.string, ""),
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => {
					return {
						validate() {
							self.hasError = false

							if (self.name === "") {
								self.hasError = true
								self.errorMessage = "This field is required"
							}
						},
						setName(name: string) {
							self.name = name
							self.hasError = false
						},
					}
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			pipelineId: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("AccessToken:Create Started")
			updateCallback = callback

			self.pipelineId = pipelineId
			self.isOpen = true
			self.form.name = ""
			self.form.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const createToken = flow(function* () {
			if (self.pipelineId === undefined) {
				return false
			}

			self.form.validate()

			if (self.form.hasError) {
				return false
			}

			try {
				self.isCreating = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.createAccessToken(
						{
							name: self.form.name,
						},
						{
							params: {
								pipeline_id: self.pipelineId,
							},
							queries: {
								organization_id:
									localStorage.getItem(
										"activeOrganization",
									) ||
									organizationsPageStore.organization?.id,
							},
						},
					),
				])

				Fathom.trackEvent("AccessToken:Create Success")

				try {
					yield updateCallback()
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isCreating = false

				return true
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return {
			openModal,
			closeModal,
			createToken,
		}
	})
