import { dictionary } from "./eventDictionary"

const eventCatalog: Record<string, boolean> = {
	// views
	[dictionary.pageView.name]: true,
	[dictionary.modalView.name]: true,

	// journeys
	[dictionary.onboardingJourney.name]: true,
	[dictionary.onboardingStepRole.name]: true,
	[dictionary.onboardingStepUseCase.name]: true,
	[dictionary.onboardingStepPreviousExperience.name]: true,

	// actions
	[dictionary.pipelineActionCreate.name]: true,
	[dictionary.pipelineActionDelete.name]: true,
	[dictionary.pipelineActionRename.name]: true,
	[dictionary.pipelineActionPause.name]: true,
	[dictionary.pipelineActionResume.name]: true,
	[dictionary.pipelineActionEdited.name]: true,
	[dictionary.pipelineConnectorTabs.name]: true,
	[dictionary.pipelineDetailsTabs.name]: true,
	[dictionary.spacesActionCreate.name]: true,
	[dictionary.spacesActionDelete.name]: true,
	[dictionary.spacesActionRename.name]: true,
	[dictionary.profileInteraction.name]: true,
	[dictionary.pipelineActionDeploy.name]: true,
}

export default eventCatalog
