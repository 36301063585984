import { observer } from "mobx-react"
import { UserMinus } from "react-feather"
import { Button, Card, Text, View } from "reshaped"
import { appStore } from "#app"
import { modalsStore } from "#modals"
import { getLinkProps, router } from "#router"
import { organizationsPageStore } from "../Organization/page.model"
import { memberPageStore } from "./page.model"
import css from "./page.module.css"

export const Member = observer(() => {
	const store = memberPageStore

	return (
		<View paddingTop={4} gap={2}>
			<View
				direction="row"
				align="end"
				justify="space-between"
				paddingBottom={7}
			>
				<View paddingBottom={1}>
					<Button
						size="small"
						className={css.back}
						variant="ghost"
						color="media"
						onClick={
							getLinkProps(router.OrganizationPage()).onClick
						}
					>
						{organizationsPageStore.organization?.name} /
					</Button>
					<Text variant="featured-1" weight="bold">
						User
					</Text>
				</View>
				{appStore.auth.isAuthenticated &&
					store.member?.id !== appStore.auth.profile.id &&
					organizationsPageStore.isAdmin && (
						<Button
							className={css.secondaryButton}
							icon={UserMinus}
							onClick={() => {
								modalsStore.deleteMember.openModal(
									organizationsPageStore.organization?.id ||
										"",
									store.member?.id || "",
									store.member?.name || "",
									() => {
										return organizationsPageStore.getOrganizationPage(
											{
												id: organizationsPageStore.selectedOrganizationId,
												hideFetching: true,
											},
										)
									},
								)
							}}
						>
							Remove User
						</Button>
					)}
			</View>
			<View direction="row" gap={6} align="stretch">
				<Card className={css.card} as="label" padding={0}>
					<View gap={1} height="100%" padding={4}>
						<Text variant="body-1" weight="bold">
							{store.member?.name}
						</Text>
						<Text variant="body-3">{store.member?.email}</Text>
					</View>
				</Card>
				<Card className={css.cardHidden} as="label" padding={0}>
					<View gap={1} height="100%" padding={4}>
						<Text variant="body-1" weight="bold">
							Last active
						</Text>
						<Text variant="body-3">January 6, 2025</Text>
					</View>
				</Card>
			</View>
		</View>
	)
})
