import { useLocation } from "@swan-io/chicane"
import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { Check, X } from "react-feather"
import { Button, useToast } from "reshaped"
import { appStore } from "#app"
import { router } from "#router"
import { organizationsPageStore } from "../Organization/page.model"
import { pipelinesPageStore } from "../Pipelines/page.model"

export const Root = observer(() => {
	const routerLocation = useLocation()
	const toast = useToast()

	useEffect(() => {
		const checkDataLoaded = () => {
			if (pipelinesPageStore.totalAmount > 0) {
				router.push("PipelinesList")
			} else {
				// Case >> Initally load the app first time
				if (
					!pipelinesPageStore.isFetchingPipelines &&
					pipelinesPageStore.totalAmount > 0
				) {
					router.push("PipelinesList")
				} else if (!pipelinesPageStore.isFetchingPipelines) {
					router.push("Onboarding")
				} else {
					setTimeout(checkDataLoaded, 50) // Check again after 50ms
				}
			}
		}
		checkDataLoaded()
	}, [])

	const acceptInvitation = async (token: string) => {
		try {
			const success = await organizationsPageStore.acceptInvite(token)
			if (success) {
				const id = toast.show({
					color: "positive",
					timeout: 5000,
					icon: Check,
					title: "Invite accepted.",
					actionsSlot: (
						<Button
							variant="faded"
							color="inherit"
							onClick={() => toast.hide(id)}
						>
							Dismiss
						</Button>
					),
				})
			}
		} catch (error) {
			const id = toast.show({
				color: "critical",
				timeout: 5000,
				icon: X,
				title: "Invite expired.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
			console.error("Invite accept Error:", error)
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (routerLocation.search.invite) {
			if (appStore.auth.isAuthenticated) {
				acceptInvitation(routerLocation.search.invite.toString())
			}
		}
	}, [routerLocation.search.invite, appStore.auth.isAuthenticated])

	return <></>
})
