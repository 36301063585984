import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import {
	ActionBar,
	Alert,
	Badge,
	Button,
	FormControl,
	Modal,
	TextField,
	View,
	useToast,
} from "reshaped"

import type React from "preact/compat"
import { useState } from "preact/hooks"
import { modalsStore } from "#modals"
import { organizationsPageStore } from "../../pages/Organization/page.model"
import css from "./modal.module.css"

export const InviteToOrganizationModal = observer(() => {
	const inviteToOrganizationModalStore = modalsStore.inviteToOrganization

	const toast = useToast()
	let alert: React.JSX.Element | null = null
	const [emails, setEmails] = useState<string[]>([])
	const [input, setInput] = useState("")

	const addEmails = (text: string) => {
		const newEmails = text
			.split(/[,\s]+/)
			.map((email) => email.trim())
			.filter(
				(email) =>
					/\S+@\S+\.\S+/.test(email) && !emails.includes(email),
			)

		if (newEmails.length) {
			setEmails([...emails, ...newEmails])
			setInput("")
		}
	}

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === " " || e.key === "Enter" || e.key === ",") {
			e.preventDefault()
			addEmails(input)
		}
	}

	const handlePaste = (e: ClipboardEvent) => {
		e.preventDefault()
		const pastedText = e.clipboardData?.getData("text") || ""
		addEmails(pastedText)
	}

	const removeEmail = (email: string) => {
		setEmails(emails.filter((e) => e !== email))
	}

	const inviteToOrganization = async () => {
		if (input.length > 0) {
			addEmails(input)
		}

		setEmails((prevEmails) => {
			const updatedEmails = [
				...prevEmails,
				...input
					.split(/[,\s]+/)
					.map((email) => email.trim())
					.filter(
						(email) =>
							/\S+@\S+\.\S+/.test(email) &&
							!prevEmails.includes(email),
					),
			]

			proceedWithInvitation(updatedEmails)
			return updatedEmails
		})
	}

	const proceedWithInvitation = async (updatedEmails: string[]) => {
		const formattedEmails = updatedEmails.map((email) => ({ email }))

		if (formattedEmails.length === 0) {
			inviteToOrganizationModalStore.form.setError(
				true,
				"Please add at least one valid email",
			)
			return
		}

		inviteToOrganizationModalStore.form.setError(false)

		try {
			const success =
				await inviteToOrganizationModalStore.inviteToOrganization(
					formattedEmails,
					organizationsPageStore.organization?.id || "",
				)
			if (success) {
				const id = toast.show({
					color: "neutral",
					timeout: 5000,
					icon: Check,
					title: "Invites sent.",
					actionsSlot: (
						<Button
							variant="faded"
							color="inherit"
							onClick={() => toast.hide(id)}
						>
							Dismiss
						</Button>
					),
				})
				setEmails([])
			}
		} catch (error) {
			console.error("Invite Error:", error)
		}
	}

	if (inviteToOrganizationModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={inviteToOrganizationModalStore.isOpen}
			padding={0}
			size="470px"
			blurredOverlay
			onClose={() => inviteToOrganizationModalStore.closeModal()}
		>
			<View padding={4} gap={4}>
				<Modal.Title>Invite by Email</Modal.Title>
				<form
					onSubmit={(event) => {
						event.preventDefault()
					}}
				>
					<FormControl
						hasError={inviteToOrganizationModalStore.form.hasError}
					>
						<TextField
							name="email"
							className={css.input}
							multiline
							value={input}
							inputAttributes={{
								onKeyDown: handleKeyDown,
								onPaste: handlePaste,
							}}
							onChange={(event) => setInput(event.value)}
							startSlot={
								<View direction="row" gap={2} wrap>
									{emails.map((email) => (
										<Badge
											key={email}
											onDismiss={() => removeEmail(email)}
											dismissAriaLabel="Remove email"
										>
											{email}
										</Badge>
									))}
								</View>
							}
							placeholder={
								emails.length === 0
									? "You can paste a list of multiple emails here"
									: ""
							}
						/>
						<FormControl.Error>
							{inviteToOrganizationModalStore.form.errorMessage}
						</FormControl.Error>
					</FormControl>
				</form>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						onClick={() =>
							inviteToOrganizationModalStore.closeModal()
						}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={inviteToOrganizationModalStore.isCreating}
						onClick={inviteToOrganization}
					>
						Invite Users
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
