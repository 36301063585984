import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import { ActionBar, Alert, Button, Modal, View, useToast } from "reshaped"
import { dictionary, track } from "#analytics/eventManager.ts"
import { isApiError } from "#api"
import { modalsStore } from "#modals"

export const DeleteSpaceModal = observer(() => {
	const deleteSpaceModalStore = modalsStore.deleteSpace
	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const deleteSpace = async () => {
		if (await deleteSpaceModalStore.deleteSpace()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: `"${deleteSpaceModalStore.spaceName}" deleted.`,
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
			track({
				event: dictionary.spacesActionDelete,
				context:
					dictionary?.spacesActionDelete?.contexts?.completed ??
					"Completed",
			})
		}
	}

	if (deleteSpaceModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)

		let error = deleteSpaceModalStore.error

		if (isApiError("deleteSpace", error)) {
			if (error.response.status === 409) {
				alert = (
					<Alert
						color="critical"
						icon={AlertCircle}
						title="This Space contains Pipelines"
					>
						Please delete all Pipelines in this Space first, in
						order to delete it.
					</Alert>
				)
			}
		}
	}

	return (
		<Modal
			className="modal"
			active={deleteSpaceModalStore.isOpen}
			padding={0}
			size="500px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Delete Space</Modal.Title>
				<Modal.Subtitle>
					Are you sure you want to delete "
					{deleteSpaceModalStore.spaceName}"?
				</Modal.Subtitle>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={deleteSpaceModalStore.isDeleting}
						onClick={() => {
							deleteSpaceModalStore.closeModal()
							track({
								event: dictionary.spacesActionDelete,
								context:
									dictionary?.spacesActionDelete?.contexts
										?.cancelled ?? "Cancelled",
							})
						}}
					>
						Cancel
					</Button>
					<Button
						color="critical"
						loading={deleteSpaceModalStore.isDeleting}
						onClick={() => {
							deleteSpace()
						}}
					>
						Delete Space
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
