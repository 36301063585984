import { observer } from "mobx-react"
import { Button, Text, View } from "reshaped"
import { dictionary, track } from "#analytics/eventManager.ts"
import type { Question } from "../page"
import { Header } from "../page"
import css from "../page.module.css"

type ExpandableTabProps = {
	step: Question
	setActiveQuestionIndex: (index: number) => void
	selectAnswer: (index: number, answer: string) => void
}
export const YourRole: React.FunctionComponent<ExpandableTabProps> = observer(
	(props) => {
		return (
			<View
				borderRadius="large"
				borderColor={{ s: undefined, m: "neutral" }}
				padding={8}
				width="100vw"
				maxWidth="900px"
			>
				<Header />
				<View>
					<View paddingBottom={2}>
						<Text
							variant={{ s: "featured-2", m: "featured-1" }}
							weight="medium"
						>
							{props.step.question}
						</Text>
					</View>
					<Text
						color="neutral-faded"
						variant={{ s: "body-2", m: "body-1" }}
					>
						This helps us improve your experience
					</Text>
					<View
						paddingTop={12}
						direction={{ s: "column", m: "row" }}
						align={{ s: "start", m: "center" }}
						gap={4}
						maxWidth="630px"
					>
						{props.step.answers.map((answer) => (
							<Button
								className={css.answer}
								key={answer}
								color="neutral"
								rounded
								variant="faded"
								onClick={() => {
									props.setActiveQuestionIndex(1)
									props.selectAnswer(0, answer)

									track({
										event: dictionary.onboardingStepRole,
										context: answer,
										properties: {
											answer: answer,
										},
									})
								}}
							>
								{answer}
							</Button>
						))}
					</View>
				</View>
			</View>
		)
	},
)
