import { types } from "mobx-state-tree"

export const CannotDeleteOrganizationModalModel = types
	.model("CannotDelete", {
		isOpen: types.optional(types.boolean, false),
		isDeleting: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const openModal = () => {
			self.isOpen = true
		}

		const closeModal = () => {
			self.isOpen = false
		}

		return { openModal, closeModal }
	})
