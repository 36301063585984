import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import {
	ActionBar,
	Alert,
	Button,
	FormControl,
	Hidden,
	Modal,
	TextField,
	View,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

export const CreateOrganizationModal = observer(() => {
	const createOrganizationModalStore = modalsStore.createOrganization

	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const createOrganization = async () => {
		if (await createOrganizationModalStore.createOrganization()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: `"${createOrganizationModalStore.form.name}" created.`,
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (createOrganizationModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={createOrganizationModalStore.isOpen}
			padding={0}
			size="400px"
			blurredOverlay
			onClose={() => createOrganizationModalStore.closeModal()}
		>
			<View padding={4} gap={4}>
				<Modal.Title>Create Organization</Modal.Title>

				<form
					onSubmit={(event) => {
						event.stopImmediatePropagation()
						event.preventDefault()
						createOrganization()
					}}
				>
					<FormControl
						hasError={createOrganizationModalStore.form.hasError}
					>
						<FormControl.Label>Name</FormControl.Label>
						<TextField
							name="name"
							value={createOrganizationModalStore.form.name}
							onChange={(event) => {
								createOrganizationModalStore.form.setName(
									event.value,
								)
							}}
							inputAttributes={{
								"data-1p-ignore": true,
							}}
						/>
						<FormControl.Error>
							{createOrganizationModalStore.form.errorMessage}
						</FormControl.Error>
					</FormControl>
					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						onClick={() =>
							createOrganizationModalStore.closeModal()
						}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={createOrganizationModalStore.isCreating}
						onClick={() => createOrganization()}
					>
						Create
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
