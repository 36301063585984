import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import { ActionBar, Alert, Button, Modal, View, useToast } from "reshaped"

import { modalsStore } from "#modals"

export const TransferOwnershipModal = observer(() => {
	const transferOwnershipModalStore = modalsStore.transferOwnership
	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const transferOwnership = async () => {
		if (await transferOwnershipModalStore.transferOwnership()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Ownership transferred.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (transferOwnershipModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={transferOwnershipModalStore.isOpen}
			padding={0}
			size="380px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Transfer Ownership</Modal.Title>
				<Modal.Subtitle>
					Are you sure you want to transfer ownership to{" "}
					<b>{transferOwnershipModalStore.memberName}</b>? Once
					transferred, you will become a member. You cannot undo this
					action.
				</Modal.Subtitle>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={
							transferOwnershipModalStore.isLoadingTransferOwnership
						}
						onClick={() => {
							transferOwnershipModalStore.closeModal()
						}}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={
							transferOwnershipModalStore.isLoadingTransferOwnership
						}
						onClick={() => {
							transferOwnership()
						}}
					>
						Confirm Transfer
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
