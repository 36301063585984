import classNames from "classnames"
import { observer } from "mobx-react"
import { getSnapshot } from "mobx-state-tree"
import { Suspense, createRef, useEffect } from "preact/compat"
import { Check, Globe, Upload } from "react-feather"
import {
	Button,
	FormControl,
	HiddenVisually,
	Tabs,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { api } from "#api"
import { AceEditor } from "#components/AceEditor"
import { modalsStore } from "#modals"

import { ExpandableTabTopPanel } from "../../../components/PipelineCreation/ExpandableTab/component"
import css from "../../../components/PipelineCreation/styles.module.css"
import { organizationsPageStore } from "../../Organization/page.model"
import { demoPipelineDetailPageStore } from "../page.model"

export const Transform = observer(() => {
	const { colorMode } = useTheme()
	const toast = useToast()
	const store = demoPipelineDetailPageStore
	const transformModalStore = modalsStore.demoPipelineTransform

	const fileInput = createRef<HTMLInputElement>()
	const editor = createRef()

	useEffect(() => {
		if (editor.current) {
			editor.current.editor.setOption(
				"wrap",
				store.transformer.editorSettings.softWrap,
			)

			editor.current.editor.setOption(
				"showInvisibles",
				store.transformer.editorSettings.showInvisibles,
			)
		}
	}, [
		editor,
		store.transformer.editorSettings.showInvisibles,
		store.transformer.editorSettings.softWrap,
	])

	const onNextClick = async () => {
		store.saveTransformer(async () => {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Transformer saved.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		})
		const parentElement: HTMLElement | null =
			document.getElementById("glassFlowSink")
		if (parentElement) {
			const button: HTMLButtonElement | null =
				parentElement.querySelector("button")

			if (
				button &&
				store.onboardingTips.mergeEmail &&
				!store.onboardingTips.seeAppliedChanges
			) {
				button.click()
				setTimeout(
					() =>
						modalsStore.onboardingTip.openModal(
							"You can see the applied changes here. <br/><br/>Now try adding more data from the <b>Data Generator</b> tab.",
							"490px",
							"showedSeeAppliedChanges",
							store.onboardingTips.seeAppliedChanges,
							true,
							true,
						),
					500,
				)
			}
		}
	}

	const onPrevClick = () => {
		store.transformer.form.setHandler(store.transformer.form.currentHandler)
		store.transformer.form.setRequirements(
			store.transformer.form.currentRequirements,
		)
		modalsStore.onboardingTip.closeModal()
	}

	return (
		<>
			<ExpandableTabTopPanel
				title="Transform: E-mail Encryption"
				onClose={transformModalStore.closeModal}
				onNextClick={() => onNextClick()}
				onPrevClick={() => onPrevClick()}
				validate={undefined}
				nextButtonLoading={store.isSaving}
				prevButtonName="Discard"
				nextButtonName="Save Changes"
				isNextButtonDisabled={!store.transformer.form.hasChanges}
			/>
			<View paddingTop={4} direction="column" gap={3}>
				<FormControl hasError={store.transformer.form.handler.hasError}>
					<Tabs>
						<View
							justify="space-between"
							direction="row"
							align="center"
							gap={6}
						>
							<View grow>
								<Tabs.List>
									<Tabs.Item value="handler">
										handler.py
									</Tabs.Item>
									<Tabs.Item value="requirements">
										requirements.txt
									</Tabs.Item>
								</Tabs.List>
							</View>
							<View direction="row" gap={3}>
								<Button
									color="neutral"
									endIcon={Globe}
									onClick={() => {
										modalsStore.demoEditEnvironmentVariables.openModal(
											getSnapshot(
												store.transformer.form
													.environmentVariables,
											),
											async (environmentVariables) => {
												if (store.pipeline) {
													// pipelineDetailPageStore.transformer.form.setEnvironmentVariables(
													// 	environmentVariables,
													// )

													await api.patchFunction(
														{
															environments:
																environmentVariables.filter(
																	({
																		name,
																		value,
																	}) => {
																		if (
																			name ===
																				"" ||
																			value ===
																				""
																		) {
																			return false
																		} else {
																			return true
																		}
																	},
																),
														},
														{
															params: {
																pipeline_id:
																	store
																		.pipeline
																		?.id,
															},
															queries: {
																organization_id:
																	localStorage.getItem(
																		"activeOrganization",
																	) ||
																	organizationsPageStore
																		.organization
																		?.id,
															},
														},
													)
												}
											},
										)
									}}
								>
									Environment Variables
								</Button>

								<View grow align="end">
									<View direction="row" gap={3}>
										<HiddenVisually>
											<input
												type="file"
												ref={fileInput}
												accept=".py"
												onChange={() => {
													const files =
														fileInput.current?.files

													if (files?.length) {
														const file = files[0]

														let reader =
															new FileReader()

														reader.addEventListener(
															"load",
															(event) => {
																if (
																	typeof event
																		.target
																		?.result ===
																	"string"
																) {
																	store.transformer.form.setHandler(
																		event
																			.target
																			.result,
																	)
																}
															},
														)

														reader.readAsText(
															file,
															"UTF-8",
														)
													}
												}}
											/>
										</HiddenVisually>
										<Button
											variant="outline"
											color="neutral"
											onClick={() => {
												fileInput.current?.click()
											}}
											icon={Upload}
										>
											Upload Handler File
										</Button>
									</View>
								</View>
							</View>
						</View>

						<Tabs.Panel value="handler">
							<View
								minHeight="300px"
								height="515px"
								paddingTop={3}
							>
								<Suspense fallback={null}>
									<AceEditor
										ref={editor}
										onLoad={(editorInstance) => {
											editorInstance.setOption(
												"wrap",
												store.transformer.editorSettings
													.softWrap,
											)

											editorInstance.setOption(
												"showInvisibles",
												store.transformer.editorSettings
													.showInvisibles,
											)
										}}
										className={classNames({
											[css.editor]: true,
											[css.editorError]:
												store.transformer.form.handler
													.hasError,
										})}
										mode="python"
										theme={
											colorMode === "dark"
												? "dracula"
												: "xcode"
										}
										onChange={(value) => {
											store.transformer.form.setHandler(
												value,
											)
										}}
										value={
											store.transformer.form.handler.value
										}
										lineHeight="1.65"
										width="100%"
										height="100%"
										fontSize={14}
										showGutter
										tabSize={4}
										readOnly={store.isSaving}
										enableBasicAutocompletion
										enableLiveAutocompletion
										showPrintMargin={false}
										editorProps={{ $blockScrolling: true }}
									/>
								</Suspense>

								<FormControl.Error>
									{
										store.transformer.form.handler
											.errorMessage
									}
								</FormControl.Error>
							</View>
						</Tabs.Panel>
						<Tabs.Panel value="requirements">
							<View
								minHeight="300px"
								height="515px"
								paddingTop={3}
							>
								<Suspense fallback={null}>
									<AceEditor
										ref={editor}
										onLoad={(editorInstance) => {
											editorInstance.setOption(
												"wrap",
												store.transformer.editorSettings
													.softWrap,
											)

											editorInstance.setOption(
												"showInvisibles",
												store.transformer.editorSettings
													.showInvisibles,
											)
										}}
										className={classNames({
											[css.editor]: true,
											[css.editorError]:
												store.transformer.form
													.requirements.hasError,
										})}
										mode="python"
										theme={
											colorMode === "dark"
												? "dracula"
												: "xcode"
										}
										onChange={(value) => {
											store.transformer.form.setRequirements(
												value,
											)
										}}
										value={
											store.transformer.form.requirements
												.value
										}
										lineHeight="1.65"
										width="100%"
										height="100%"
										fontSize={14}
										showGutter
										tabSize={4}
										readOnly={store.isSaving}
										enableBasicAutocompletion
										enableLiveAutocompletion
										showPrintMargin={false}
										editorProps={{ $blockScrolling: true }}
									/>
								</Suspense>

								<FormControl.Error>
									{
										store.transformer.form.handler
											.errorMessage
									}
								</FormControl.Error>
							</View>
						</Tabs.Panel>
					</Tabs>
				</FormControl>
			</View>
		</>
	)
})
