import { observer } from "mobx-react"
import { Copy, Info, X } from "react-feather"
import { Button, Link, Text, View, useTheme, useToast } from "reshaped"

import { AceEditor } from "#components/AceEditor"

import css from "../detailsPage.module.css"
import { pipelineDetailPageStore } from "../page.model"

export const SDKSnippetSource = observer(() => {
	const { colorMode } = useTheme()
	const toast = useToast()

	const installCode = "pip install glassflow"
	const sendDataCode = `from glassflow import PipelineDataSource
source = PipelineDataSource(
    pipeline_id="${pipelineDetailPageStore.pipeline?.id}",
    pipeline_access_token="${pipelineDetailPageStore.accessTokens.tokens.length ? pipelineDetailPageStore.accessTokens.tokens[0].token : "PIPELINE_ACCESS_TOKEN"}")
for i in range(20):
    event = {"data": "hello GF {}".format(i)}
    res = source.publish(event)
    if res.status_code == 200:
        print("Sent event {event} to pipeline".format(event=event))`

	return (
		<View direction="column" gap={3}>
			<Text variant="body-2">
				Run this code to send data using GlassFlow Python SDK.{" "}
				<Link
					href="https://www.glassflow.dev/docs/python/publish-consume-events"
					attributes={{ target: "_blank" }}
					variant="plain"
				>
					View docs
				</Link>
			</Text>

			<Text variant="body-2">Install:</Text>
			<View className={css.editorWrapper}>
				<Button
					onClick={async () => {
						await navigator.clipboard.writeText(installCode)

						const id = toast.show({
							color: "neutral",
							icon: Copy,
							title: "Copied code snippet",
							actionsSlot: (
								<Button
									variant="ghost"
									icon={X}
									onClick={() => toast.hide(id)}
								/>
							),

							timeout: "short",
							position: "bottom-end",
						})
					}}
					variant="faded"
					icon={Copy}
					color="neutral"
					className={css.copy}
				>
					Copy
				</Button>
				<AceEditor
					className={css.editor}
					style={{
						border: "0px",
						background: "#16181E",
						zIndex: 0,
					}}
					mode="python"
					onLoad={(editor) => {
						editor.renderer.setPadding(15)
						editor.renderer.setScrollMargin(15, 15, 15, 15)
					}}
					theme={colorMode === "dark" ? "dracula" : "xcode"}
					value={installCode}
					lineHeight="1.65"
					highlightActiveLine={false}
					showGutter={false}
					width="100%"
					height="55px"
					fontSize={14}
					tabSize={4}
					readOnly={true}
					showPrintMargin={false}
				/>
			</View>
			<Text variant="body-2">Send data:</Text>
			<View className={css.editorWrapper}>
				<Button
					variant="faded"
					onClick={async () => {
						await navigator.clipboard.writeText(sendDataCode)

						const id = toast.show({
							color: "neutral",
							icon: Copy,
							title: "Copied code snippet",
							actionsSlot: (
								<Button
									variant="ghost"
									icon={X}
									onClick={() => toast.hide(id)}
								/>
							),

							timeout: "short",
							position: "bottom-end",
						})
					}}
					icon={Copy}
					color="neutral"
					className={css.copy}
				>
					Copy
				</Button>
				<AceEditor
					className={css.editor}
					style={{
						border: "0px",
						background: "#16181E",
						zIndex: 0,
					}}
					mode="python"
					onLoad={(editor) => {
						editor.renderer.setPadding(15)
						editor.renderer.setScrollMargin(15, 15, 15, 15)
					}}
					theme={colorMode === "dark" ? "dracula" : "xcode"}
					value={sendDataCode}
					lineHeight="1.65"
					highlightActiveLine={false}
					showGutter={false}
					width="100%"
					height="240px"
					fontSize={14}
					tabSize={4}
					readOnly={true}
					showPrintMargin={false}
				/>
			</View>
		</View>
	)
})

export const SDKSnippetSink = observer(() => {
	const { colorMode } = useTheme()
	const toast = useToast()

	const installCode = "pip install glassflow"
	const receiveDataCode = `from glassflow import PipelineDataSink
sink = PipelineDataSink(
    pipeline_id="${pipelineDetailPageStore.pipeline?.id}",
    pipeline_access_token="${pipelineDetailPageStore.accessTokens.tokens.length ? pipelineDetailPageStore.accessTokens.tokens[0].token : "PIPELINE_ACCESS_TOKEN"}")
for i in range(20):
    result = sink.consume()
    print(result.event())`

	return (
		<View direction="column" gap={3}>
			<Text variant="body-2">
				Run this code to consume transformed events using GlassFlow
				Python SDK.{" "}
				<Link
					href="https://www.glassflow.dev/docs/python/publish-consume-events"
					attributes={{ target: "_blank" }}
					variant="plain"
				>
					View docs
				</Link>
			</Text>
			<Text variant="body-2">Install:</Text>
			<View className={css.editorWrapper}>
				<Button
					onClick={async () => {
						await navigator.clipboard.writeText(installCode)

						const id = toast.show({
							color: "neutral",
							icon: Copy,
							title: "Copied code snippet",
							actionsSlot: (
								<Button
									variant="ghost"
									icon={X}
									onClick={() => toast.hide(id)}
								/>
							),

							timeout: "short",
							position: "bottom-end",
						})
					}}
					variant="faded"
					icon={Copy}
					color="neutral"
					className={css.copy}
				>
					Copy
				</Button>
				<AceEditor
					className={css.editor}
					style={{
						border: "0px",
						background: "#16181E",
						zIndex: 0,
					}}
					mode="python"
					onLoad={(editor) => {
						editor.renderer.setPadding(15)
						editor.renderer.setScrollMargin(15, 15, 15, 15)
					}}
					theme={colorMode === "dark" ? "dracula" : "xcode"}
					value={installCode}
					lineHeight="1.65"
					highlightActiveLine={false}
					showGutter={false}
					width="100%"
					height="55px"
					fontSize={14}
					tabSize={4}
					readOnly={true}
					showPrintMargin={false}
				/>
			</View>
			<Text variant="body-2">Receive data:</Text>
			<View className={css.editorWrapper}>
				<Button
					variant="faded"
					onClick={async () => {
						await navigator.clipboard.writeText(receiveDataCode)

						const id = toast.show({
							color: "neutral",
							icon: Copy,
							title: "Copied code snippet",
							actionsSlot: (
								<Button
									variant="ghost"
									icon={X}
									onClick={() => toast.hide(id)}
								/>
							),

							timeout: "short",
							position: "bottom-end",
						})
					}}
					icon={Copy}
					color="neutral"
					className={css.copy}
				>
					Copy
				</Button>
				<AceEditor
					className={css.editor}
					style={{
						border: "0px",
						background: "#16181E",
						zIndex: 0,
					}}
					mode="python"
					onLoad={(editor) => {
						editor.renderer.setPadding(15)
						editor.renderer.setScrollMargin(15, 15, 15, 15)
					}}
					theme={colorMode === "dark" ? "dracula" : "xcode"}
					value={receiveDataCode}
					lineHeight="1.65"
					highlightActiveLine={false}
					showGutter={false}
					width="100%"
					height="190px"
					fontSize={14}
					tabSize={4}
					readOnly={true}
					showPrintMargin={false}
				/>
			</View>
		</View>
	)
})
