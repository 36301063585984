import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { Text, View } from "reshaped"
import { dictionary, track } from "#analytics/eventManager.ts"

export const NotFound = observer(() => {
	useEffect(() => {
		track({
			event: dictionary.pageView,
			context:
				dictionary?.pageView?.contexts?.notFound ?? "Page not found",
		})
	}, [])
	return (
		<View gap={3} paddingTop={4}>
			<View paddingBottom={1}>
				<Text variant="title-3">Page not found</Text>
			</View>
			<Text variant="body-2">
				We could not find what you are looking for.
			</Text>
		</View>
	)
})
