import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { useEffect } from "react"
import {
	Actionable,
	Button,
	FormControl,
	Hidden,
	HiddenVisually,
	Loader,
	Select,
	Text,
	TextField,
	View,
} from "reshaped"

import { appStore } from "#app"
import { AceEditor } from "#components/AceEditor"

import { pipelineCreatePageStore } from "./page.model"

import classNames from "classnames"
import { dictionary, track } from "#analytics/eventManager.ts"
import { modalsStore } from "#modals"
import { ExpandableTab } from "../../components/PipelineCreation/ExpandableTab/component"
import { organizationsPageStore } from "../Organization/page.model"
import css from "./page.module.css"

const createSpaceButton = (
	getSpaces: () => void,
	setSpace: (id: string) => void,
) => (
	<Actionable
		onClick={() => {
			modalsStore.createSpace.openModal(
				organizationsPageStore.selectedOrganizationId || "",
				async (space) => {
					await getSpaces()
					setSpace(space.id)
				},
			)
		}}
	>
		<Text color="primary">Create another Space</Text>
	</Actionable>
)

export const PipelineCreate = observer(() => {
	if (!appStore.auth.isAuthenticated) {
		throw Error()
	}

	const store = pipelineCreatePageStore

	useEffect(() => {
		track({
			event: dictionary.pageView,
			context:
				dictionary?.pageView?.contexts?.pipelineCreate ??
				"Pipeline Create",
		})
	}, [])

	return (
		<View paddingTop={4}>
			{/* Trigger the loading of the lazy Ace Chunk when we enter the page, not just when the transformation step is shown. */}
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{/* End of Trigger */}
			<View direction="column" gap={6}>
				<Text variant="title-3">Create</Text>
				<View gap={4} direction="row" align="stretch">
					<View.Item columns={4}>
						<ExpandableTab
							name={
								store.sourceForm.connector.type === "webhook"
									? "Webhook"
									: store.sourceForm.connector.type ===
											"amazon_sqs"
										? "Amazon SQS"
										: store.sourceForm.connector.type ===
												"google_pubsub"
											? "Google Pub/Sub"
											: "SDK"
							}
							label="Source"
							isError={store.sourceForm.hasError}
							isEditable
							onClick={() =>
								modalsStore.createPipelineSource.openModal()
							}
						/>
					</View.Item>
					<View.Item columns={4}>
						<ExpandableTab
							name="Function"
							label="Transform"
							isError={store.transformerForm.hasError}
							isEditable
							onClick={() =>
								modalsStore.createPipelineTransform.openModal()
							}
						/>
					</View.Item>
					<View.Item columns={4}>
						<ExpandableTab
							name={
								store.sinkForm.connector.type === "webhook"
									? "Webhook"
									: store.sinkForm.connector.type ===
											"hubspot"
										? "Hubspot"
										: store.sinkForm.connector.type ===
												"slack"
											? "Slack"
											: store.sinkForm.connector.type ===
													"clickhouse"
												? "ClickHouse"
												: store.sinkForm.connector
															.type ===
														"amazon_s3"
													? "Amazon S3"
													: "SDK"
							}
							label="Sink"
							isError={store.sinkForm.hasError}
							isEditable
							onClick={() =>
								modalsStore.createPipelineSink.openModal()
							}
						/>
					</View.Item>
				</View>
				<View gap={4} direction="column" paddingTop={2}>
					<View.Item columns={4}>
						<FormControl hasError={store.setupForm.name.hasError}>
							<FormControl.Label>Pipeline Name</FormControl.Label>
							<TextField
								name="pipelineName"
								value={store.setupForm.name.value}
								onChange={(event) => {
									store.setupForm.setName(event.value)
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{store.setupForm.name.errorMessage}
							</FormControl.Error>
						</FormControl>
					</View.Item>
					<View.Item columns={4}>
						<FormControl>
							<View
								direction="row"
								align="center"
								gap={2}
								className={classNames({
									[css.spaceLabel]: store.isFetchingSpaces,
								})}
							>
								<Hidden hide={!store.isFetchingSpaces}>
									<View paddingBottom={1}>
										<Loader size="small" />
									</View>
								</Hidden>
								<FormControl.Label>Space</FormControl.Label>
							</View>
							<View paddingBottom={3}>
								{!store.isFetchingSpaces &&
								store.spaces.length === 1 ? (
									<Text
										variant="caption-1"
										color="neutral-faded"
									>
										You currently only have a single space.{" "}
										{createSpaceButton(
											store.getSpaces,
											pipelineCreatePageStore.setupForm
												.setSpace,
										)}
									</Text>
								) : (
									<Text
										variant="caption-1"
										color="neutral-faded"
									>
										Which Space should this pipeline reside
										in?{" "}
										{createSpaceButton(
											store.getSpaces,
											pipelineCreatePageStore.setupForm
												.setSpace,
										)}
									</Text>
								)}
							</View>
							<Select
								disabled={
									store.isFetchingSpaces ||
									store.spaces.length === 1
								}
								options={store.spaces.map((space) => {
									return {
										label: space.name,
										value: space.id,
									}
								})}
								name="space"
								value={store.setupForm.space.value}
								onChange={(event) => {
									store.setupForm.setSpace(event.value)
								}}
							/>
						</FormControl>
					</View.Item>
				</View>
				<View>
					<Button
						color="primary"
						loading={store.isCreating}
						onClick={async () => {
							store.submit()
						}}
					>
						Deploy Pipeline
					</Button>
				</View>
			</View>
		</View>
	)
})
