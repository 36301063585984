import * as Fathom from "fathom-client"
import { cast, flow, types } from "mobx-state-tree"
import {
	InviteModel,
	MembersModel,
	OrganisationDetailsModel,
	OrganisationModel,
	api,
} from "#api"
import { appStore } from "#app"
import { apiPageSize } from "#constants"

export const OrganizationsPage = types
	.model({
		organizations: types.array(OrganisationDetailsModel),
		organization: types.maybeNull(OrganisationModel),
		invites: types.maybeNull(InviteModel),
		members: types.maybeNull(MembersModel),
		isFetchingOrganizations: types.optional(types.boolean, false),
		isFetchingOrganization: types.optional(types.boolean, false),
		isFetchingMembers: types.optional(types.boolean, false),
		isLoadingAccept: types.optional(types.boolean, false),
		isLoadingRevoke: types.optional(types.boolean, false),
		isLoadingTransferOwnership: types.optional(types.boolean, false),
		isFetchingInvites: types.optional(types.boolean, false),
		selectedOrganizationId: types.maybeNull(types.string),
	})
	.views((self) => ({
		get isAdmin() {
			if (!self.organization) return false

			const currentOrg = self.organizations.find(
				(org) => org.id === self.organization?.id,
			)
			return currentOrg?.role === "admin"
		},
	}))
	.actions((self) => {
		const reset = () => {
			self.organization = null
			self.invites = null
			self.members = null
		}

		const findStoredOrganizationId = () => {
			const storedOrgId = localStorage.getItem("activeOrganization")
			return (
				self.organizations.find((org) => org.id === storedOrgId)?.id ||
				(self.organizations.length > 0
					? self.organizations[0].id
					: null)
			)
		}

		const getOrganizationsPage = flow(function* ({
			page,
			size = apiPageSize,
			hideFetching = false,
		}) {
			if (!hideFetching) self.isFetchingOrganizations = true

			try {
				const result = yield api.listOrganizations({
					queries: { page, page_size: size },
				})

				self.organizations = cast(result.organizations)

				if (
					!localStorage.getItem("activeOrganization") &&
					result.organizations.length
				) {
					localStorage.setItem(
						"activeOrganization",
						result.organizations[0].id,
					)
				}

				self.selectedOrganizationId = findStoredOrganizationId()
				saveSelectedOrganization(findStoredOrganizationId() || "")
			} catch (error) {
				console.error("Failed to fetch organizations:", error)
			} finally {
				self.isFetchingOrganizations = false
			}
		})

		const getOrganizationPage = flow(function* ({
			id,
			hideFetching = false,
		}) {
			if (!hideFetching) self.isFetchingOrganization = true

			try {
				const organization = yield api.getOrganization({
					queries: { organization_id: id },
				})

				self.organization = cast(organization)
			} catch (error) {
				console.error("Failed to fetch organization:", error)
			} finally {
				self.isFetchingOrganization = false
			}
		})

		const getMembers = flow(function* () {
			if (!self.organization?.id) return

			self.isFetchingMembers = true

			try {
				const members = yield api.listMembers({
					queries: {
						organization_id: self.organization?.id,
						page: 1,
						page_size: 9999,
					},
				})

				self.members = cast(members)
			} catch (error) {
				console.error("Failed to fetch members:", error)
			} finally {
				self.isFetchingMembers = false
			}
		})

		const getPendingInvites = flow(function* () {
			if (!self.organization?.id) return

			self.isFetchingInvites = true

			try {
				const invites = yield api.ListOrganizationInvites({
					queries: {
						organization_id: self.organization?.id,
						page: 1,
						page_size: 9999,
					},
				})

				self.invites = cast(invites)
			} catch (error) {
				console.error("Failed to fetch invites:", error)
			} finally {
				self.isFetchingInvites = false
			}
		})

		const revokeInvite = flow(function* (
			organizationId: string,
			inviteId: string,
		) {
			try {
				self.isLoadingRevoke = true

				yield Promise.all([
					api.deleteInvite(undefined, {
						params: { invite_id: inviteId },
						queries: { organization_id: organizationId },
					}),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				Fathom.trackEvent("Organization:Invite Revoke Success")

				yield new Promise((resolve) => setTimeout(resolve, 200))
				getPendingInvites()

				self.isLoadingRevoke = false

				return true
			} catch (error) {
				console.error("Failed to revoke invite:", error)

				return false
			}
		})

		const acceptInvite = flow(function* (token: string) {
			try {
				self.isLoadingAccept = true

				const response = yield Promise.all([
					api.acceptInvite(undefined, {
						params: { invite_token: token },
					}),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				Fathom.trackEvent("Organization:Invite Accept Success")
				saveSelectedOrganization(response[0].id)

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isLoadingAccept = false

				return true
			} catch (error) {
				console.error("Failed to accept invite:", error)
				self.isLoadingAccept = false
				throw error
			}
		})

		const getSelectedOrganization = () => {
			self.selectedOrganizationId = findStoredOrganizationId()
		}

		const saveSelectedOrganization = (organizationId: string) => {
			localStorage.setItem("activeOrganization", organizationId)
			self.selectedOrganizationId = findStoredOrganizationId()
		}

		const deleteSelectedOrganization = () => {
			localStorage.removeItem("activeOrganization")
			self.selectedOrganizationId = null
		}

		return {
			getOrganizationsPage,
			getOrganizationPage,
			getSelectedOrganization,
			saveSelectedOrganization,
			deleteSelectedOrganization,
			revokeInvite,
			getPendingInvites,
			getMembers,
			acceptInvite,
			reset,
		}
	})

export const organizationsPageStore = OrganizationsPage.create()
