import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: () => Promise<void>

export const UploadFunctionFileModalModel = types
	.model("UploadFunctionFileModal", {
		pipelineId: types.optional(types.string, ""),
		isOpen: types.optional(types.boolean, false),
		isUploading: types.optional(types.boolean, false),
		form: types.optional(
			types
				.model("Form", {
					file: types.optional(
						types.model("file", {
							value: types.maybe(types.frozen()),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
				})
				.actions((self) => {
					const validate = () => {
						if (self.file.value === undefined) {
							self.file.hasError = true
							self.file.errorMessage = "This field is required"
						} else {
							self.file.hasError = false
						}
					}

					const setFile = (file: File) => {
						self.file.value = file
						validate()
					}

					return {
						validate,
						setFile,
					}
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const upload = flow(function* () {
			self.error = undefined
			self.form.validate()

			if (self.form.file.hasError) {
				return false
			}

			try {
				self.isUploading = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.uploadFunctionArtifacts(
						{
							file: self.form.file.value as File,
						},
						{
							params: {
								pipeline_id: self.pipelineId,
							},
							queries: {
								organization_id:
									localStorage.getItem(
										"activeOrganization",
									) ||
									organizationsPageStore.organization?.id,
							},
						},
					),
				])

				Fathom.trackEvent("Pipeline:FunctionFileUpload Success")

				try {
					yield updateCallback()
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isUploading = false

				return true
			} catch (error) {
				self.isUploading = false
				self.error = error

				return false
			}
		})

		const openModal = (
			pipelineId: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Pipeline:FunctionFileUpload Started")
			updateCallback = callback
			self.pipelineId = pipelineId

			self.isOpen = true
			self.form.file.value = undefined
			self.form.file.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		return {
			openModal,
			closeModal,
			upload,
		}
	})
