import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const SignUp = z
  .object({ access_token: z.string(), id_token: z.string() })
  .passthrough();
const CreateOrganization = z.object({ name: z.string() }).passthrough();
const Organization = CreateOrganization.and(
  z
    .object({
      id: z.string(),
      created_at: z.string().datetime({ offset: true }),
    })
    .passthrough()
);
const Profile = z
  .object({
    id: z.string(),
    home_organization: Organization,
    name: z.string(),
    email: z.string(),
    provider: z.string(),
    external_settings: z.unknown().nullable(),
    subscriber_id: z.string(),
  })
  .passthrough();
const Error = z.object({ detail: z.string() }).passthrough();
const PersonalAccessToken = z.string();
const ListPersonalAccessTokens = z
  .object({ tokens: z.array(PersonalAccessToken) })
  .passthrough();
const PaginationResponse = z
  .object({ total_amount: z.number().int() })
  .passthrough();
const OrganizationScope = Organization.and(
  z.object({ role: z.string() }).passthrough()
);
const OrganizationScopes = z.array(OrganizationScope);
const ListOrganizationScopes = PaginationResponse.and(
  z.object({ organizations: OrganizationScopes }).passthrough()
);
const PatchOrganization = z.object({ name: z.string() }).passthrough();
const CreateInvite = z.object({ email: z.string() }).passthrough();
const Invite = CreateInvite.and(z.object({ id: z.string() }).passthrough());
const Invites = z.array(Invite);
const ListOrganizationInvites = PaginationResponse.and(
  z.object({ items: Invites }).passthrough()
);
const CreateInvites = z
  .object({ invites: z.array(CreateInvite) })
  .passthrough();
const OrganizationMemberRole = z.enum(["admin", "member"]);
const OrganizationMember = z
  .object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    role: OrganizationMemberRole,
  })
  .passthrough();
const OrganizationMembers = z.array(OrganizationMember);
const ListOrganizationMembers = PaginationResponse.and(
  z.object({ items: OrganizationMembers }).passthrough()
);
const PatchOrganizationMember = z
  .object({ role: OrganizationMemberRole })
  .passthrough();
const SecretKey = z.string();
const Secret = z.object({ key: SecretKey }).passthrough();
const Secrets = z.array(Secret);
const ListOrganizationSecrets = PaginationResponse.and(
  z.object({ secrets: Secrets }).passthrough()
);
const CreateSecret = z
  .object({ key: SecretKey, value: z.string() })
  .passthrough();
const CreateSpace = z.object({ name: z.string() }).passthrough();
const Space = CreateSpace.and(
  z
    .object({
      id: z.string(),
      created_at: z.string().datetime({ offset: true }),
    })
    .passthrough()
);
const SpaceScope = Space.and(
  z.object({ permission: z.string() }).passthrough()
);
const SpaceScopes = z.array(SpaceScope);
const ListSpaceScopes = PaginationResponse.and(
  z.object({ spaces: SpaceScopes }).passthrough()
);
const UpdateSpace = z.object({ name: z.string() }).passthrough();
const BasePipeline = z
  .object({
    name: z.string(),
    space_id: z.string(),
    metadata: z.object({}).partial().passthrough().nullable(),
  })
  .passthrough();
const PipelineState = z.enum(["running", "paused"]);
const Pipeline = BasePipeline.and(
  z
    .object({
      id: z.string(),
      created_at: z.string().datetime({ offset: true }),
      state: PipelineState,
    })
    .passthrough()
);
const SpacePipeline = Pipeline.and(
  z.object({ space_name: z.string() }).passthrough()
);
const SpacePipelines = z.array(SpacePipeline);
const ListPipelines = PaginationResponse.and(
  z.object({ pipelines: SpacePipelines }).passthrough()
);
const ConnectorValue_Value = z.object({ value: z.string() }).passthrough();
const SecretRef = z
  .object({ type: z.literal("organization"), key: SecretKey })
  .passthrough();
const ConnectorValue_SecretRef = z
  .object({ secret_ref: SecretRef })
  .passthrough();
const ConnectorValue = z.union([
  ConnectorValue_Value,
  ConnectorValue_SecretRef,
]);
const SourceConnector_GooglePubSub = z
  .object({
    kind: z.literal("google_pubsub"),
    config: z
      .object({
        project_id: z.string(),
        subscription_id: z.string(),
        credentials_json: z.string(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        project_id: ConnectorValue,
        subscription_id: ConnectorValue,
        credentials_json: ConnectorValue,
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SourceConnector_AmazonSQS = z
  .object({
    kind: z.literal("amazon_sqs"),
    config: z
      .object({
        queue_url: z.string(),
        aws_region: z.string(),
        aws_access_key: z.string(),
        aws_secret_key: z.string(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        queue_url: ConnectorValue,
        aws_region: ConnectorValue,
        aws_access_key: ConnectorValue,
        aws_secret_key: ConnectorValue,
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const ConnectorValueList = z.array(z.string());
const SourceConnector_Postgres = z
  .object({
    kind: z.literal("postgres"),
    config: z
      .object({
        db_host: z.string(),
        db_port: z.string().optional().default("5432"),
        db_user: z.string(),
        db_pass: z.string(),
        db_name: z.string(),
        db_sslmode: z.string().optional(),
        replication_slot: z.string(),
        publication: z.string().optional(),
        replication_output_plugin_name: z
          .string()
          .optional()
          .default("wal2json"),
        replication_output_plugin_args: z.array(z.string()).optional(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        db_host: ConnectorValue,
        db_port: ConnectorValue.optional(),
        db_user: ConnectorValue,
        db_pass: ConnectorValue,
        db_name: ConnectorValue,
        db_sslmode: ConnectorValue.optional(),
        replication_slot: ConnectorValue,
        publication: ConnectorValue.optional(),
        replication_output_plugin_name: ConnectorValue.optional(),
        replication_output_plugin_args: ConnectorValueList.optional(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SourceConnector = z.discriminatedUnion("kind", [
  SourceConnector_GooglePubSub,
  SourceConnector_AmazonSQS,
  SourceConnector_Postgres,
]);
const SinkConnector_WebhookConfigHeadersList = z.array(
  z.object({ name: z.string(), value: z.string() }).passthrough()
);
const SinkConnector_Webhook = z
  .object({
    kind: z.literal("webhook"),
    config: z
      .object({
        url: z.string(),
        method: z.enum(["GET", "POST", "PUT", "PATCH", "DELETE"]),
        headers: z.array(
          z.object({ name: z.string(), value: z.string() }).passthrough()
        ),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        url: ConnectorValue,
        method: ConnectorValue,
        headers: SinkConnector_WebhookConfigHeadersList,
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SinkConnector_Clickhouse = z
  .object({
    kind: z.literal("clickhouse"),
    config: z
      .object({
        addr: z.string(),
        database: z.string(),
        username: z.string(),
        password: z.string(),
        table: z.string(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        addr: ConnectorValue,
        database: ConnectorValue,
        username: ConnectorValue,
        password: ConnectorValue,
        table: ConnectorValue,
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SinkConnector_AmazonS3 = z
  .object({
    kind: z.literal("amazon_s3"),
    config: z
      .object({
        s3_bucket: z.string(),
        s3_key: z.string(),
        aws_region: z.string(),
        aws_access_key: z.string(),
        aws_secret_key: z.string(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        s3_bucket: ConnectorValue,
        s3_key: ConnectorValue,
        aws_region: ConnectorValue,
        aws_access_key: ConnectorValue,
        aws_secret_key: ConnectorValue,
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SinkConnector_SnowflakeCDCJSON = z
  .object({
    kind: z.literal("snowflake_cdc_json"),
    config: z
      .object({
        account: z.string(),
        warehouse: z.string(),
        db_user: z.string(),
        db_pass: z.string(),
        db_name: z.string(),
        db_schema: z.string(),
        db_host: z.string().optional(),
        db_port: z.string().optional().default("443"),
        db_role: z.string().optional(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        account: ConnectorValue,
        warehouse: ConnectorValue,
        db_user: ConnectorValue,
        db_pass: ConnectorValue,
        db_name: ConnectorValue,
        db_schema: ConnectorValue,
        db_host: ConnectorValue.optional(),
        db_port: ConnectorValue.optional(),
        db_role: ConnectorValue.optional(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SinkConnector_PineconeJSON = z
  .object({
    kind: z.literal("pinecone_json"),
    config: z
      .object({
        api_key: z.string(),
        api_host: z.string(),
        api_source_tag: z.string().optional(),
        index_host: z.string(),
        client_headers: z
          .array(
            z.object({ name: z.string(), value: z.string() }).passthrough()
          )
          .optional(),
      })
      .passthrough()
      .optional(),
    configuration: z
      .object({
        api_key: ConnectorValue,
        api_host: ConnectorValue,
        api_source_tag: ConnectorValue.optional(),
        index_host: ConnectorValue,
        client_headers: z
          .array(
            z
              .object({ name: z.string(), value: ConnectorValue_Value })
              .passthrough()
          )
          .optional(),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SinkConnector_MongoDBJSON = z
  .object({
    kind: z.literal("mongodb_json"),
    config: z
      .object({ connection_uri: z.string(), db_name: z.string() })
      .passthrough()
      .optional(),
    configuration: z
      .object({ connection_uri: ConnectorValue, db_name: ConnectorValue })
      .passthrough()
      .optional(),
  })
  .passthrough();
const SinkConnector = z.discriminatedUnion("kind", [
  SinkConnector_Webhook,
  SinkConnector_Clickhouse,
  SinkConnector_AmazonS3,
  SinkConnector_SnowflakeCDCJSON,
  SinkConnector_PineconeJSON,
  SinkConnector_MongoDBJSON,
]);
const FunctionEnvironments = z.array(
  z.object({ name: z.string(), value: z.string() }).passthrough()
);
const CreatePipeline = BasePipeline.and(
  z
    .object({
      transformation_function: z.string(),
      transformation_requirements: z.array(z.string()),
      requirements_txt: z.string(),
      source_connector: SourceConnector.nullable(),
      sink_connector: SinkConnector.nullable(),
      environments: FunctionEnvironments.nullable(),
      state: PipelineState,
    })
    .partial()
    .passthrough()
);
const GetDetailedSpacePipeline = SpacePipeline.and(
  z
    .object({
      source_connector: SourceConnector.nullable(),
      sink_connector: SinkConnector.nullable(),
      environments: FunctionEnvironments.nullable(),
    })
    .passthrough()
);
const patchPipeline_Body = z
  .object({
    name: z.string(),
    state: PipelineState,
    metadata: z.object({}).partial().passthrough().nullable(),
    source_connector: SourceConnector.nullable(),
    sink_connector: SinkConnector.nullable(),
    environments: FunctionEnvironments.nullable(),
  })
  .partial()
  .passthrough();
const PipelineFunctionOutput = z
  .object({ environments: FunctionEnvironments.nullable() })
  .passthrough();
const patchFunction_Body = z
  .object({ environments: FunctionEnvironments.nullable() })
  .partial()
  .passthrough();
const SourceFile = z
  .object({ name: z.string(), content: z.string() })
  .passthrough();
const SourceFiles = z.array(SourceFile);
const SeverityCode = z.number();
const FunctionLogEntry = z
  .object({
    level: z.string(),
    severity_code: SeverityCode.int(),
    timestamp: z.string().datetime({ offset: true }),
    payload: z.object({ message: z.string() }).passthrough(),
  })
  .passthrough();
const FunctionLogs = z.array(FunctionLogEntry);
const EventContext = z
  .object({
    request_id: z.string(),
    external_id: z.string().optional(),
    receive_time: z.string().datetime({ offset: true }),
  })
  .passthrough();
const ConsumeOutputEvent = z
  .object({
    req_id: z.string().optional(),
    receive_time: z.string().datetime({ offset: true }).optional(),
    payload: z.unknown(),
    event_context: EventContext,
    status: z.string(),
    response: z.unknown().optional(),
    error_details: z.string().optional(),
    stack_trace: z.string().optional(),
  })
  .passthrough();
const ConsumeInputEvent = z
  .object({
    req_id: z.string().optional(),
    receive_time: z.string().datetime({ offset: true }).optional(),
    payload: z.unknown(),
    event_context: EventContext,
  })
  .passthrough();
const CreateAccessToken = z.object({ name: z.string() }).passthrough();
const AccessToken = CreateAccessToken.and(
  z
    .object({
      id: z.string(),
      token: z.string(),
      created_at: z.string().datetime({ offset: true }),
    })
    .passthrough()
);
const AccessTokens = z.array(AccessToken);
const ListAccessTokens = PaginationResponse.and(
  z.object({ access_tokens: AccessTokens }).passthrough()
);
const QueryRangeMatrix = z.unknown();
const PipelineInputQueueRelativeLatencyMetricsResponse = z
  .object({
    input_queue_total_push_events: QueryRangeMatrix.nullable(),
    input_queue_latency: QueryRangeMatrix.nullable(),
  })
  .passthrough();
const UpdatePipeline = z
  .object({
    name: z.string(),
    transformation_function: z.string().optional(),
    transformation_requirements: z.array(z.string()).optional(),
    requirements_txt: z.string().optional(),
    metadata: z.object({}).partial().passthrough().nullish(),
    source_connector: SourceConnector.nullish(),
    sink_connector: SinkConnector.nullish(),
    environments: FunctionEnvironments.nullish(),
  })
  .passthrough();
const SeverityCodeInput = z.union([
  z.literal(100),
  z.literal(200),
  z.literal(400),
  z.literal(500),
]);

export const schemas = {
  SignUp,
  CreateOrganization,
  Organization,
  Profile,
  Error,
  PersonalAccessToken,
  ListPersonalAccessTokens,
  PaginationResponse,
  OrganizationScope,
  OrganizationScopes,
  ListOrganizationScopes,
  PatchOrganization,
  CreateInvite,
  Invite,
  Invites,
  ListOrganizationInvites,
  CreateInvites,
  OrganizationMemberRole,
  OrganizationMember,
  OrganizationMembers,
  ListOrganizationMembers,
  PatchOrganizationMember,
  SecretKey,
  Secret,
  Secrets,
  ListOrganizationSecrets,
  CreateSecret,
  CreateSpace,
  Space,
  SpaceScope,
  SpaceScopes,
  ListSpaceScopes,
  UpdateSpace,
  BasePipeline,
  PipelineState,
  Pipeline,
  SpacePipeline,
  SpacePipelines,
  ListPipelines,
  ConnectorValue_Value,
  SecretRef,
  ConnectorValue_SecretRef,
  ConnectorValue,
  SourceConnector_GooglePubSub,
  SourceConnector_AmazonSQS,
  ConnectorValueList,
  SourceConnector_Postgres,
  SourceConnector,
  SinkConnector_WebhookConfigHeadersList,
  SinkConnector_Webhook,
  SinkConnector_Clickhouse,
  SinkConnector_AmazonS3,
  SinkConnector_SnowflakeCDCJSON,
  SinkConnector_PineconeJSON,
  SinkConnector_MongoDBJSON,
  SinkConnector,
  FunctionEnvironments,
  CreatePipeline,
  GetDetailedSpacePipeline,
  patchPipeline_Body,
  PipelineFunctionOutput,
  patchFunction_Body,
  SourceFile,
  SourceFiles,
  SeverityCode,
  FunctionLogEntry,
  FunctionLogs,
  EventContext,
  ConsumeOutputEvent,
  ConsumeInputEvent,
  CreateAccessToken,
  AccessToken,
  AccessTokens,
  ListAccessTokens,
  QueryRangeMatrix,
  PipelineInputQueueRelativeLatencyMetricsResponse,
  UpdatePipeline,
  SeverityCodeInput,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/organization",
    alias: "getOrganization",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Organization,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "patch",
    path: "/organization",
    alias: "patchOrganization",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Organization,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/organization",
    alias: "deleteOrganization",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `Not allowed`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `Organization is not empty`,
        schema: Error.and(
          z
            .object({
              context: z.object({ existed_space_id: z.string() }).passthrough(),
            })
            .passthrough()
        ),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/organization/invites",
    alias: "ListOrganizationInvites",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListOrganizationInvites,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/organization/invites",
    alias: "createInvites",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateInvites,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/organization/invites/:invite_id",
    alias: "deleteInvite",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "invite_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/organization/members",
    alias: "listMembers",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListOrganizationMembers,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/organization/members/:member_id",
    alias: "getMember",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "member_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: OrganizationMember,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "patch",
    path: "/organization/members/:member_id",
    alias: "patchMember",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PatchOrganizationMember,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "member_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: OrganizationMember,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `Not allowed`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/organization/members/:member_id",
    alias: "deleteMember",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "member_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `Not allowed`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/organizations",
    alias: "listOrganizations",
    requestFormat: "json",
    parameters: [
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListOrganizationScopes,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/organizations",
    alias: "createOrganization",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
    ],
    response: Organization,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 405,
        description: `Organizations limit is reached`,
        schema: Error.and(
          z
            .object({
              context: z.object({ limit: z.number().int() }).passthrough(),
            })
            .passthrough()
        ),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines",
    alias: "listPipelines",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Query",
        schema: z.array(z.string()).optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("asc"),
      },
    ],
    response: ListPipelines,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines",
    alias: "createPipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreatePipeline,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "echo-function",
        type: "Query",
        schema: z.boolean().optional().default(false),
      },
    ],
    response: Pipeline.and(
      z.object({ access_token: z.string() }).passthrough()
    ),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id",
    alias: "getPipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: GetDetailedSpacePipeline,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "patch",
    path: "/pipelines/:pipeline_id",
    alias: "patchPipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: patchPipeline_Body,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: GetDetailedSpacePipeline,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/pipelines/:pipeline_id",
    alias: "deletePipeline",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/access_tokens",
    alias: "listAccessTokens",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListAccessTokens,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/access_tokens",
    alias: "createAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: AccessToken,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/pipelines/:pipeline_id/access_tokens/:token_id",
    alias: "updateAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "token_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: AccessToken,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/pipelines/:pipeline_id/access_tokens/:token_id",
    alias: "deleteAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "token_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/functions/main",
    alias: "getFunction",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: PipelineFunctionOutput,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "patch",
    path: "/pipelines/:pipeline_id/functions/main",
    alias: "patchFunction",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: patchFunction_Body,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: PipelineFunctionOutput,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/functions/main/artifacts",
    alias: "uploadFunctionArtifacts",
    requestFormat: "form-data",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z
          .object({
            file: z.instanceof(File),
            requirementsTxt: z.instanceof(File).optional(),
            requirements: z.string().optional(),
          })
          .passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 425,
        description: `Still in progress`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/functions/main/artifacts/latest",
    alias: "getArtifact",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z
      .object({
        files: SourceFiles,
        transformation_function: z.string().nullish(),
        requirements_txt: z.string().nullish(),
      })
      .passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/functions/main/logs",
    alias: "getFunctionLogs",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().optional().default(10),
      },
      {
        name: "page_token",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "severity_code",
        type: "Query",
        schema: z
          .union([
            z.literal(100),
            z.literal(200),
            z.literal(400),
            z.literal(500),
          ])
          .optional(),
      },
      {
        name: "start_time",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "end_time",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.object({ logs: FunctionLogs, next: z.string() }).passthrough(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/functions/main/test",
    alias: "testFunction",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: ConsumeOutputEvent,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/metrics/input_queue_relative_latency",
    alias: "getPipelineInputQueueRelativeLatencyMetrics",
    description: `The endpoint gets the time series for total pushed events in input queue and their relative latency
`,
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "duration",
        type: "Query",
        schema: z.string().optional().default("1h"),
      },
    ],
    response: PipelineInputQueueRelativeLatencyMetricsResponse,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/status/access_token",
    alias: "statusAccessToken",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "X-Pipeline-Access-Token",
        type: "Header",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/topics/:topic/events",
    alias: "pushEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "Authorization",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "X-Pipeline-Access-Token",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_access_token",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "topic",
        type: "Path",
        schema: z.literal("input"),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/topics/failed/events/consume",
    alias: "consumeFailedEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "Authorization",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "X-Pipeline-Access-Token",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_access_token",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: ConsumeOutputEvent,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 429,
        description: `Too many requests`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/topics/failed/events/preview",
    alias: "previewFailedEvents",
    description: `The endpoint gets the last failed message and tries to lookup for &#x27;num&#x27; other failed messages (if exist).
`,
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "num",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: z.array(ConsumeOutputEvent),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/topics/input/events/preview",
    alias: "previewInputEvents",
    description: `The endpoint gets the last input message and tries to lookup for &#x27;num&#x27; other input messages (if exist).
`,
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "num",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: z.array(ConsumeInputEvent),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/pipelines/:pipeline_id/topics/output/events/consume",
    alias: "consumeOutputEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "Authorization",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "X-Pipeline-Access-Token",
        type: "Header",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_access_token",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: ConsumeOutputEvent,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 429,
        description: `Too many requests`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/pipelines/:pipeline_id/topics/output/events/preview",
    alias: "previewOutputEvents",
    description: `The endpoint gets the last output message and tries to lookup for &#x27;num&#x27; other output messages (if exist).
`,
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "pipeline_id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "num",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: z.array(ConsumeOutputEvent),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/secrets",
    alias: "listOrganizationSecrets",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
    ],
    response: ListOrganizationSecrets,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/secrets",
    alias: "setOrganizationSecret",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateSecret,
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/secrets/:secret_key",
    alias: "deleteOrganizationSecret",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "secret_key",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/spaces",
    alias: "listSpaces",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page_size",
        type: "Query",
        schema: z.number().int().gte(0).optional().default(50),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().gte(1).optional().default(1),
      },
      {
        name: "order_by",
        type: "Query",
        schema: z.enum(["asc", "desc"]).optional().default("asc"),
      },
    ],
    response: ListSpaceScopes,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/spaces",
    alias: "createSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: Space,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/spaces/:space_id",
    alias: "getSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: Space,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "put",
    path: "/spaces/:space_id",
    alias: "updateSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: Space,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "delete",
    path: "/spaces/:space_id",
    alias: "deleteSpace",
    requestFormat: "json",
    parameters: [
      {
        name: "organization_id",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "space_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `Space is not empty`,
        schema: z
          .object({ msg: z.string(), existed_pipeline_id: z.string() })
          .passthrough(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/users",
    alias: "signUp",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SignUp,
      },
    ],
    response: Profile,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 409,
        description: `Already exists`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/users/me",
    alias: "getProfile",
    requestFormat: "json",
    response: Profile,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/users/me/external_settings",
    alias: "SaveUserExternalSettings",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({}).partial().passthrough().nullable(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/users/me/invites/:invite_token",
    alias: "acceptInvite",
    requestFormat: "json",
    parameters: [
      {
        name: "invite_token",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: Organization,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not found`,
        schema: z.void(),
      },
      {
        status: 410,
        description: `Invite is expired`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "get",
    path: "/users/me/personal_access_tokens",
    alias: "listPersonalAccessTokens",
    requestFormat: "json",
    response: ListPersonalAccessTokens,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
  {
    method: "post",
    path: "/users/me/personal_access_tokens",
    alias: "resetPersonalAccessToken",
    requestFormat: "json",
    response: ListPersonalAccessTokens,
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
