import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: () => Promise<void>

export const RenamePipelineModalModel = types
	.model("RenamePipelineModal", {
		isOpen: types.optional(types.boolean, false),
		isRenaming: types.optional(types.boolean, false),
		pipelineId: types.maybe(types.string),
		currentPipelineName: types.optional(types.string, ""),
		form: types.optional(
			types
				.model("Form", {
					name: types.optional(types.string, ""),
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => {
					const validate = () => {
						if (self.name === "") {
							self.hasError = true
							self.errorMessage = "This field is required"
						} else {
							self.hasError = false
						}
					}

					const setName = (name: string) => {
						self.name = name
					}

					return { validate, setName }
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			pipelineId: string,
			currentPipelineName: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Pipeline:Rename Started")
			updateCallback = callback

			self.pipelineId = pipelineId
			self.currentPipelineName = currentPipelineName
			self.isOpen = true
			self.form.name = ""
			self.form.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const renamePipeline = flow(function* () {
			if (!self.pipelineId) {
				return
			}

			self.form.validate()

			if (self.form.hasError) {
				return false
			}

			try {
				self.isRenaming = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.patchPipeline(
						{
							name: self.form.name,
						},
						{
							params: { pipeline_id: self.pipelineId },
							queries: {
								organization_id:
									localStorage.getItem(
										"activeOrganization",
									) ||
									organizationsPageStore.organization?.id,
							},
						},
					),
				])

				Fathom.trackEvent("Pipeline:Rename Success")

				try {
					yield updateCallback()
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isRenaming = false

				return true
			} catch (error) {
				self.isRenaming = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, renamePipeline }
	})
