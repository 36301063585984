import { observer } from "mobx-react"
import { AlertCircle } from "react-feather"
import {
	Actionable,
	Alert,
	Button,
	FormControl,
	Hidden,
	Modal,
	Select,
	Text,
	TextField,
	View,
} from "reshaped"
import { dictionary, track } from "#analytics/eventManager"
import { modalsStore } from "#modals"
import { organizationsPageStore } from "../../pages/Organization/page.model"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"

const createSpaceButton = (
	getSpaces: () => void,
	setSpace: (id: string) => void,
) => (
	<Actionable
		onClick={() => {
			modalsStore.createSpace.openModal(
				organizationsPageStore.selectedOrganizationId || "",
				async (space) => {
					await getSpaces()
					setSpace(space.id)
				},
			)
		}}
	>
		<Text color="primary">Create another Space</Text>
	</Actionable>
)

export const CreatePipelineModal = observer(() => {
	const createPipelineModalStore = modalsStore.createPipeline

	const store = pipelineCreatePageStore
	const createPipeline = async () => {
		store.setupForm.validate()
		if (!store.setupForm.hasError) {
			await store.createInitialPipeline()
			createPipelineModalStore.closeModal(false)
		}
	}

	return (
		<Modal
			className="modal"
			active={createPipelineModalStore.isOpen}
			padding={8}
			size="400px"
			blurredOverlay
		>
			<View gap={4}>
				<Modal.Title>Create New Pipeline</Modal.Title>
				<form
					onSubmit={(event) => {
						event.stopImmediatePropagation()
						event.preventDefault()
					}}
				>
					<View gap={3}>
						<FormControl hasError={store.setupForm.name.hasError}>
							<FormControl.Label>Name</FormControl.Label>
							<TextField
								name="name"
								// value={store.name.value}
								onChange={(event) => {
									store.setupForm.setName(event.value)
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
						</FormControl>

						<FormControl>
							<FormControl.Label>Space</FormControl.Label>
							<Select
								disabled={
									store.isFetchingSpaces ||
									store.spaces.length === 1
								}
								options={store.spaces.map((space) => {
									return {
										label: space.name,
										value: space.id,
									}
								})}
								name="space"
								value={store.setupForm.space.value}
								onChange={(event) => {
									store.setupForm.setSpace(event.value)
								}}
							/>
							<div
								style={{
									marginTop: "8px",
								}}
							>
								{createSpaceButton(
									store.getSpaces,
									pipelineCreatePageStore.setupForm.setSpace,
								)}
							</div>

							<FormControl.Error>
								{store.setupForm.name.errorMessage}
							</FormControl.Error>
						</FormControl>
					</View>
					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>

				{store.error && (
					<Alert
						color="critical"
						icon={AlertCircle}
						title="Something went wrong. Please try again."
					/>
				)}

				<View direction="row" gap={4} justify="end">
					<Button
						disabled={store.isCreating}
						color="neutral"
						onClick={() => {
							createPipelineModalStore.closeModal(true)
							track({
								event: dictionary.pipelineActionCreate,
								context:
									dictionary?.pipelineActionCreate?.contexts
										?.cancelled ?? "Cancelled",
							})
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						loading={store.isCreating}
						disabled={!store.setupForm.name.value}
						onClick={() => {
							createPipeline()
							track({
								event: dictionary.pipelineActionCreate,
								context:
									dictionary?.pipelineActionCreate?.contexts
										?.completed ?? "Completed",
							})
						}}
					>
						Create Pipeline
					</Button>
				</View>
			</View>
		</Modal>
	)
})
