import { observer } from "mobx-react"
import { useEffect } from "react"
import { Lock } from "react-feather"
import { Icon, Skeleton, Text, View } from "reshaped"
import { dictionary, track } from "#analytics/eventManager.ts"
import { appId, appStore, isProduction } from "#app"
import { metricsApi } from "#metrics"
import { modalsStore } from "#modals"
import { getLinkProps, router } from "#router"
import File from "../../../public/icons/file.svg?react"
import Github from "../../../public/icons/github.svg?react"
import { CreateOption } from "../../components/CreateOption/component"
import { pipelineCreatePageStore } from "../PipelineCreate/page.model"
import css from "./page.module.css"
function sendMetricEvent(buttonType: string) {
	if (appStore.auth.isAuthenticated && isProduction()) {
		metricsApi.postMetricEvents(
			[
				{
					name: "button_clicked",
					created_at: new Date().toISOString(),
					data: {
						user_id: appStore.auth.profile.id,
						email: appStore.auth.profile.email,
						timestamp: new Date().toISOString(),
						button_type: buttonType,
					},
					metadata: {
						source: appId,
					},
				},
			],
			{},
		)
	}
}

export const Home = observer(() => {
	const store = pipelineCreatePageStore
	store.getSpaces()

	useEffect(() => {
		track({
			event: dictionary.pageView,
			context: dictionary?.pageView?.contexts?.home ?? "Home",
		})
	}, [])

	return (
		<View paddingTop={4}>
			<View paddingBottom={24}>
				{appStore.auth.isAuthenticated ? (
					<Text variant="title-1" className={css.welcome}>
						Welcome {appStore.auth.profile.name}!
					</Text>
				) : (
					<Skeleton width="100%" height={9} />
				)}
				<Text variant="body-1">
					Create a pipeline using the visual editor or use the jupyter
					quick start guide
				</Text>
			</View>
			<View paddingBottom={16}>
				<Text variant="title-4">Examples</Text>
				<View insetTop={4} gap={3} direction="row" align="stretch">
					<View.Item columns={4}>
						<CreateOption
							icon={
								<Icon size={9} color="primary" svg={<Lock />} />
							}
							href={router.PipelinesCreateEmailEncryption()}
							name="E-mail Encryption"
							onClick={
								getLinkProps(
									router.PipelinesCreateEmailEncryption(),
								).onClick
							}
						/>
					</View.Item>
					<View.Item columns={4}>
						<CreateOption
							target="_blank"
							icon={<Github height="36px" />}
							href="https://github.com/glassflow/glassflow-examples/blob/main/getting-started.ipynb"
							name="Quick Start with Jupyter"
							onClick={() => {
								track({
									event: dictionary.pipelineActionCreate,
									context:
										dictionary?.pipelineActionCreate
											?.contexts?.jupyter ?? "Jupyter",
								})
								sendMetricEvent("quick-start-via-jupyter")
							}}
						/>
					</View.Item>
				</View>
			</View>
			<Text variant="title-4">Start from scratch</Text>
			<View insetTop={4} gap={3} direction="row" align="stretch">
				<View.Item columns={4}>
					<CreateOption
						isBlue
						icon={<File height="36px" />}
						name="New Pipeline"
						onClick={() => {
							store.setupForm.reset()
							modalsStore.createPipeline.openModal()
							track({
								event: dictionary.pipelineActionCreate,
								context:
									dictionary?.pipelineActionCreate?.contexts
										?.newPipeline ?? "New Pipeline",
							})
						}}
					/>
				</View.Item>
			</View>
		</View>
	)
})
