import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"
import { organizationsPageStore } from "../../pages/Organization/page.model"

let updateCallback: (
	organization: Awaited<ReturnType<typeof api.createOrganization>>,
) => Promise<void>

export const CreateOrganizationModalModel = types
	.model("CreateOrganizationModal", {
		isOpen: types.optional(types.boolean, false),
		isCreating: types.optional(types.boolean, false),
		form: types.optional(
			types
				.model("Form", {
					name: types.optional(types.string, ""),
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => {
					const validate = () => {
						if (self.name === "") {
							self.hasError = true
							self.errorMessage = "This field is required"
						} else {
							self.hasError = false
						}
					}

					const setName = (name: string) => {
						self.name = name
					}

					return { validate, setName }
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			callback: (
				organization: Awaited<
					ReturnType<typeof api.createOrganization>
				>,
			) => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Organization:Create Started")
			updateCallback = callback

			self.isOpen = true
			self.form.name = ""
			self.form.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const createOrganization = flow(function* () {
			self.form.validate()

			if (self.form.hasError) {
				return false
			}

			try {
				self.isCreating = true

				const [organization] = yield Promise.all([
					api.createOrganization({
						name: self.form.name,
					}),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				Fathom.trackEvent("Organization:Create Success")
				organizationsPageStore.saveSelectedOrganization(organization.id)
				try {
					yield updateCallback(organization)
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isCreating = false

				return true
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, createOrganization }
	})
