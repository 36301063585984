import { observer } from "mobx-react"
import { Code, Copy, Info, X } from "react-feather"
import {
	Button,
	FormControl,
	Icon,
	Link,
	RadioGroup,
	Text,
	TextArea,
	TextField,
	Tooltip,
	View,
} from "reshaped"

import { useTheme, useToast } from "reshaped"
import { AceEditor } from "#components/AceEditor"

import { useEffect, useState } from "preact/hooks"
import { dictionary, track } from "#analytics/eventManager.ts"
import Amazon from "../../../../public/icons/amazonSQS.svg?react"
import Google from "../../../../public/icons/googlePubSub.svg?react"
import Postgres from "../../../../public/icons/postgres.svg?react"
import Webhook from "../../../../public/icons/webhook.svg?react"
import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { pipelineCreatePageStore } from "../../PipelineCreate/page.model"
import { SDKSnippetSource } from "../components/SDKSnippet"
import css from "../detailsPage.module.css"
import { pipelineDetailPageStore } from "../page.model"
export const SourceOptions = [
	{ value: "sdk", name: "SDK", icon: <Icon size={5} svg={<Code />} /> },
	{
		value: "webhook",
		name: "Webhook",
		icon: (
			<Webhook
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "postgres",
		name: "Postgres",
		badge: "managed",
		icon: (
			<Postgres
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "amazon_sqs",
		name: "Amazon SQS",
		badge: "managed",
		icon: (
			<Amazon
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "google_pubsub",
		name: "Google Pub/Sub",
		badge: "managed",
		icon: (
			<Google
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
]

export const ManagedSources = SourceOptions.filter(
	(source) => source.badge === "managed",
)

export const UnmanagedSources = SourceOptions.filter((source) => !source.badge)

interface DataSourceProps {
	isEdit: boolean
	onDiscard: () => void
	onSave: () => void
}

export const DataSource: React.FC<DataSourceProps> = observer(
	({ isEdit, onDiscard, onSave }) => {
		let details: React.JSX.Element | null = null
		const store = pipelineCreatePageStore
		const store_detail = pipelineDetailPageStore
		const [changeHappens, setChangeHappens] = useState(false)
		const { colorMode } = useTheme()
		const toast = useToast()

		const webhookUrl = `curl -X POST <https://api.glassflow.dev/v1/pipelines/>${pipelineDetailPageStore.pipeline?.id}/topics/input/events
		-H "Content-Type: application/json"
		-H "X-PIPELINE-ACCESS-TOKEN: ${pipelineDetailPageStore.accessTokens.tokens.length ? pipelineDetailPageStore.accessTokens.tokens[0].token : "PIPELINE_ACCESS_TOKEN"}'
		-d '{
			"key1": "value1",
			"key2": "value2"
			}'`

		useEffect(() => {
			store.sourceForm.setType(store_detail.sourceConnectorType)
		}, [store.sourceForm.setType, store_detail.sourceConnectorType])

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (store_detail.sourceConnectorType === "amazon_sqs") {
				if (store_detail.sourceConnectorValues) {
					store.sourceForm.setSQSConnector(
						store_detail.sourceConnectorValues as {
							queue_url: string
							aws_region: string
							aws_access_key: string
							aws_secret_key: string
						},
					)
				}
			} else if (store_detail.sourceConnectorType === "google_pubsub") {
				if (store_detail.sourceConnectorValues) {
					store.sourceForm.setPubsubConnector(
						store_detail.sourceConnectorValues as {
							project_id: string
							subscription_id: string
							credentials_json: string
						},
					)
				}
			} else if (store_detail.sourceConnectorType === "postgres") {
				if (store_detail.sourceConnectorValues) {
					store.sourceForm.setPostgresConnector(
						store_detail.sourceConnectorValues as {
							db_host: string
							db_port: string
							db_user: string
							db_pass: string
							db_name: string
							replication_slot: string
							// replication_output_plugin_args: string[]
						},
					)
				}
			}
		}, [store.sourceForm.connector.type])

		if (store.sourceForm.connector.type === "google_pubsub") {
			details = (
				<View
					gap={4}
					direction="column"
					paddingTop={6}
					key="google_pubsub"
				>
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Pull data directly from Google Pub/Sub topic.{" "}
							<Link
								href="https://www.glassflow.dev/docs/integrations/sources/google-pubsub"
								attributes={{ target: "_blank" }}
								variant="plain"
							>
								View docs
							</Link>
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>

					<View direction="row" wrap gap={4}>
						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.projectId
										.hasError
								}
							>
								<FormControl.Label>
									Project ID
								</FormControl.Label>
								<TextField
									name="projectId"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.projectId.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"google_pubsub"
										) {
											store.sourceForm.connector.meta.setProjectId(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.projectId.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta
										.subscriptionId.hasError
								}
							>
								<FormControl.Label>
									Subscription ID
								</FormControl.Label>
								<TextField
									name="subscriptionId"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.subscriptionId.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"google_pubsub"
										) {
											store.sourceForm.connector.meta.setSubscriptionId(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.subscriptionId.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={12}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.credentials
										.hasError
								}
							>
								<FormControl.Label>
									Credentials JSON
								</FormControl.Label>
								<TextArea
									name="credentials"
									resize="none"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.credentials.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"google_pubsub"
										) {
											store.sourceForm.connector.meta.setCredentials(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.credentials.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>
					</View>
				</View>
			)
		} else if (store.sourceForm.connector.type === "amazon_sqs") {
			details = (
				<View
					gap={4}
					direction="column"
					paddingTop={6}
					key="amazon_sqs"
				>
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Pull data directly from Amazon Simple Queue
							Service (SQS).{" "}
							<Link
								href="https://www.glassflow.dev/docs/integrations/sources/amazon-sqs"
								attributes={{ target: "_blank" }}
								variant="plain"
							>
								View docs
							</Link>
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>

					<View direction="row" wrap gap={4}>
						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.queueUrl
										.hasError
								}
							>
								<FormControl.Label>Queue URL</FormControl.Label>
								<TextField
									name="queueUrl"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta.queueUrl
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"amazon_sqs"
										) {
											store.sourceForm.connector.meta.setQueueUrl(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta.queueUrl
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.region
										.hasError
								}
							>
								<FormControl.Label>
									AWS Region
								</FormControl.Label>
								<TextField
									name="region"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta.region
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"amazon_sqs"
										) {
											store.sourceForm.connector.meta.setRegion(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta.region
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.accessKey
										.hasError
								}
							>
								<FormControl.Label>
									AWS Access Key
								</FormControl.Label>
								<TextField
									name="accessKey"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.accessKey.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"amazon_sqs"
										) {
											store.sourceForm.connector.meta.setAccessKey(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.accessKey.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.secretKey
										.hasError
								}
							>
								<FormControl.Label>
									AWS Secret Key
								</FormControl.Label>
								<TextField
									name="secretKey"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.secretKey.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"amazon_sqs"
										) {
											store.sourceForm.connector.meta.setSecretKey(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.secretKey.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>
					</View>
				</View>
			)
		} else if (store.sourceForm.connector.type === "postgres") {
			details = (
				<View gap={4} direction="column" paddingTop={6} key="postgres">
					{!pipelineDetailPageStore.viewOnlyMode && (
						<Text variant="body-2">
							Pull data directly from Postgres database.{" "}
							<Link
								href="https://www.glassflow.dev/docs/integrations/sources/postgres-cdc"
								attributes={{ target: "_blank" }}
								variant="plain"
							>
								View docs
							</Link>
						</Text>
					)}
					<Text variant="title-6">Connector Details</Text>

					<View direction="row" wrap gap={4}>
						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.host
										.hasError
								}
							>
								<FormControl.Label>Host</FormControl.Label>
								<TextField
									name="host"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta.host
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setHost(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta.host
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.port
										.hasError
								}
							>
								<FormControl.Label>Port</FormControl.Label>
								<TextField
									name="port"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta.port
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setPort(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta.port
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.username
										.hasError
								}
							>
								<FormControl.Label>User Name</FormControl.Label>
								<TextField
									name="username"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta.username
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setUsername(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta.username
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta.password
										.hasError
								}
							>
								<FormControl.Label>Password</FormControl.Label>
								<TextField
									name="password"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta.password
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setPassword(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
										type: "password",
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta.password
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta
										.database_name.hasError
								}
							>
								<FormControl.Label>
									Database Name
								</FormControl.Label>
								<TextField
									name="datbase_name"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.database_name.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setDatabaseName(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.database_name.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>

						<View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta
										.replication_slot.hasError
								}
							>
								<FormControl.Label>
									Replication Slot
								</FormControl.Label>
								<TextField
									name="replication_slot"
									disabled={
										pipelineDetailPageStore.viewOnlyMode
									}
									value={
										store.sourceForm.connector.meta
											.replication_slot.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setReplicationSlot(
												event.value,
											)
											setChangeHappens(true)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.replication_slot.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item>
						{/*
						<View.Item columns={6}>
							<FormControl>
								<FormControl.Label>
									Replication Output Parameters (optional)
								</FormControl.Label>
								<TextField
									name="replication_output_plugin_args"
									value={store.sourceForm.connector.meta.replication_output_plugin_args
										.toJSON()
										.toString()}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											if (
												event &&
												event.value !== undefined
											) {
												const newValue = event.value

												store.sourceForm.connector.meta.setReplicationOutputPluginSlot(
													newValue,
												)
												// Update the replication_output_plugin_args with the new value
												// store.sourceForm.connector.meta.replication_output_plugin_args.replace([
												// 	{
												// 		value: newValue,
												// 		hasError: false,
												// 		errorMessage: "",
												// 	},
												// ]);
												setChangeHappens(true)
											} else {
												console.error(
													"Event target or value is undefined",
												)
											}
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
							</FormControl>
						</View.Item> */}

						{/* <View.Item columns={6}>
							<FormControl
								hasError={
									store.sourceForm.connector.meta
										.replication_output_plugin_args.hasError
								}
							>
								<FormControl.Label>
									Replication Output Plugin Args
								</FormControl.Label>
								<TextField
									name="replication_output_plugin_args"
									value={
										store.sourceForm.connector.meta
											.replication_output_plugin_args
											.value
									}
									onChange={(event) => {
										if (
											store.sourceForm.connector.type ===
											"postgres"
										) {
											store.sourceForm.connector.meta.setReplicationOutputPluginSlot(
												event.value,
											)
										}
									}}
									inputAttributes={{
										"data-1p-ignore": true,
									}}
								/>
								<FormControl.Error>
									{
										store.sourceForm.connector.meta
											.replication_output_plugin_args
											.errorMessage
									}
								</FormControl.Error>
							</FormControl>
						</View.Item> */}
					</View>
				</View>
			)
		} else if (store.sourceForm.connector.type === "sdk") {
			details = (
				<View gap={4} direction="column" paddingTop={6} key="sdk">
					<SDKSnippetSource />
				</View>
			)
		} else if (store.sourceForm.connector.type === "webhook") {
			details = (
				<View gap={4} direction="column" paddingTop={6} key="webhook">
					<Text variant="body-2">
						Send data directly from any external tool using Webhook.{" "}
						<Link
							href="https://www.glassflow.dev/docs/integrations/sources/webhook"
							attributes={{ target: "_blank" }}
							variant="plain"
						>
							View docs
						</Link>
					</Text>
					<View className={css.editorWrapperWithHeader}>
						<Button
							variant="faded"
							onClick={async () => {
								await navigator.clipboard.writeText(webhookUrl)

								const id = toast.show({
									color: "neutral",
									icon: Copy,
									title: "Copied code snippet",
									actionsSlot: (
										<Button
											variant="ghost"
											icon={X}
											onClick={() => toast.hide(id)}
										/>
									),

									timeout: "short",
									position: "bottom-end",
								})
							}}
							icon={Copy}
							color="neutral"
							className={css.copy}
						>
							Copy
						</Button>
						<AceEditor
							className={css.editor}
							style={{
								border: "0px",
								background: "#16181E",
								zIndex: 0,
								height: "230px",
								paddingTop: "45px",
							}}
							setOptions={{
								firstLineNumber: 5,
							}}
							mode="python"
							onLoad={(editor) => {
								editor.renderer.setPadding(15)
								editor.renderer.setScrollMargin(15, 15, 15, 15)
							}}
							theme={colorMode === "dark" ? "dracula" : "xcode"}
							value={webhookUrl}
							lineHeight="1.65"
							highlightActiveLine={false}
							showGutter={false}
							width="100%"
							height="190px"
							fontSize={14}
							tabSize={4}
							readOnly={true}
							showPrintMargin={false}
						/>
					</View>
				</View>
			)
		}

		const saveSource = async (editType: string) => {
			if (
				(await store.isSourceFormValid()) &&
				store_detail.pipeline?.id
			) {
				await pipelineDetailPageStore.patchSourceAndSink(
					store_detail.pipeline?.id,
					"source",
				)
				onSave()
				if (editType === "edit") {
					track({
						event: dictionary.pipelineActionEdited,
						context:
							dictionary?.pipelineActionEdited?.contexts
								?.source ?? "Source",
					})
				}
			}
		}

		const continueButton = (
			<View paddingTop={8}>
				<Button
					color="primary"
					loading={pipelineDetailPageStore.isSaving}
					onClick={() => saveSource("create")}
				>
					Continue
				</Button>
			</View>
		)

		const discardAndSaveBtn = (
			<View grow align="end">
				<View direction="row" gap={3}>
					<Button
						color="neutral"
						disabled={false}
						onClick={onDiscard}
					>
						Discard
					</Button>
					<Button
						variant="solid"
						color="primary"
						onClick={() => {
							saveSource("edit")
						}}
						disabled={
							pipelineDetailPageStore.viewOnlyMode ||
							(pipelineDetailPageStore.sourceConnectorType ===
								pipelineCreatePageStore.sourceForm.connector
									.type &&
								!changeHappens)
						}
						loading={pipelineDetailPageStore.isSaving}
					>
						Save Changes
					</Button>
				</View>
			</View>
		)

		return (
			<>
				<View>
					<View>
						<View paddingBottom={6} direction="row" align="center">
							<Text variant="title-5">Set Up Data Source </Text>
							<Tooltip
								text={
									<>
										Select a data source to bring your data
										into the pipeline. <br />
										<a
											href="https://www.glassflow.dev/docs/concepts/data-source"
											target="_blank"
											rel="noopener noreferrer"
											style={{
												color: "#FFA24B",
												textDecoration: "underline",
											}}
										>
											Learn more
										</a>
									</>
								}
								position="end"
							>
								{(attributes) => (
									<Button
										variant="ghost"
										icon={Info}
										attributes={attributes}
									/>
								)}
							</Tooltip>
							{isEdit && discardAndSaveBtn}
						</View>
						{!pipelineDetailPageStore.viewOnlyMode && (
							<>
								<div
									style={{
										display: "flex",
										marginBottom: "8px",
									}}
								>
									<div>
										<Text
											variant="body-2"
											attributes={{
												style: { color: "#a8adb8" },
											}}
										>
											Self-managed
										</Text>
									</div>
								</div>
								<View direction="row" align="stretch" gap={4}>
									<RadioGroup
										name="source"
										onChange={(event) => {
											store.sourceForm.setType(
												event.value as
													| "sdk"
													| "webhook",
											)
										}}
									>
										{UnmanagedSources.map((option) => (
											<CardRadio
												key={option.value}
												isSelected={
													store.sourceForm.connector
														.type === option.value
												}
												value={option.value}
												name={option.name}
												// badge={option.badge}
												icon={option.icon}
											/>
										))}
									</RadioGroup>
								</View>
								<div
									style={{
										paddingTop: "10px",
										paddingBottom: "6px",
									}}
								>
									<Text
										variant="body-2"
										attributes={{
											style: { color: "#a8adb8" },
										}}
									>
										Managed
									</Text>
								</div>

								<View direction="row" align="stretch" gap={4}>
									<RadioGroup
										name="source"
										onChange={(event) => {
											store.sourceForm.setType(
												event.value as
													| "amazon_sqs"
													| "google_pubsub"
													| "postgres",
											)
										}}
									>
										{ManagedSources.map((option) => (
											<CardRadio
												key={option.value}
												isSelected={
													store.sourceForm.connector
														.type === option.value
												}
												value={option.value}
												name={option.name}
												// badge={option.badge}
												icon={option.icon}
											/>
										))}
									</RadioGroup>
								</View>
							</>
						)}
						{details}
					</View>
					{!isEdit && continueButton}
				</View>
			</>
		)
	},
)
