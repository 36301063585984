import { observer } from "mobx-react"
import { ActionBar, Button, Modal, View } from "reshaped"
import { router } from "#router"

import { modalsStore } from "#modals"

export const CannotDeleteOrganizationModal = observer(() => {
	const store = modalsStore.cannotDeleteOrganization

	return (
		<Modal
			className="modal"
			active={store.isOpen}
			padding={0}
			size="410px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Cannot Delete Organization</Modal.Title>
				<Modal.Subtitle>
					You cannot delete this organization while it has pipelines.
					Please delete all pipelines first.
				</Modal.Subtitle>
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={store.isDeleting}
						onClick={() => store.closeModal()}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={store.isDeleting}
						onClick={() => {
							router.push("PipelinesList")
							store.closeModal()
						}}
					>
						View Pipelines
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
