import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import {
	ActionBar,
	Alert,
	Button,
	FormControl,
	Hidden,
	Modal,
	TextField,
	View,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

export const RenameOrganizationModal = observer(() => {
	const renameOrganizationModalStore = modalsStore.renameOrganization

	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const renameOrganization = async () => {
		if (await renameOrganizationModalStore.renameOrganization()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Successfully renamed Organization",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (renameOrganizationModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={renameOrganizationModalStore.isOpen}
			padding={0}
			size="400px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Rename Organization</Modal.Title>

				<form
					onSubmit={(event) => {
						event.stopImmediatePropagation()
						event.preventDefault()
						renameOrganization()
					}}
				>
					<View gap={3}>
						<FormControl
							hasError={
								renameOrganizationModalStore.form.hasError
							}
						>
							<FormControl.Label>Name</FormControl.Label>
							<TextField
								name="newName"
								value={renameOrganizationModalStore.form.name}
								onChange={(event) => {
									renameOrganizationModalStore.form.setName(
										event.value,
									)
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{renameOrganizationModalStore.form.errorMessage}
							</FormControl.Error>
						</FormControl>
					</View>

					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						disabled={renameOrganizationModalStore.isCreating}
						color="neutral"
						onClick={() => {
							renameOrganizationModalStore.closeModal()
						}}
					>
						Discard
					</Button>
					<Button
						color="primary"
						loading={renameOrganizationModalStore.isCreating}
						onClick={() => renameOrganization()}
					>
						Change Name
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
