import { useEffect } from "react"
import { Text, View } from "reshaped"
import { dictionary, track } from "#analytics/eventManager.ts"
import css from "../page.module.css"

export const ThankYou: React.FunctionComponent = () => {
	useEffect(() => {
		track({
			event: dictionary.onboardingJourney,
			context:
				dictionary?.onboardingJourney?.contexts?.completed ??
				"Completed",
		})
	}, [])

	return (
		<View
			borderRadius="large"
			borderColor={{ s: undefined, m: "neutral" }}
			padding={8}
			width="100vw"
			maxWidth="900px"
		>
			<View
				className={css.ThankYou}
				paddingTop={20}
				paddingBottom={20}
				direction="row"
				align="center"
				justify="center"
			>
				<Text variant="title-1" align="center">
					Thank you for your feedback!
				</Text>
			</View>
		</View>
	)
}
