import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"

let updateCallback: () => Promise<void>

export const RenameOrganizationModalModel = types
	.model("RenameOrganizationModal", {
		isOpen: types.optional(types.boolean, false),
		isCreating: types.optional(types.boolean, false),
		organizationId: types.maybe(types.string),
		currentOrganizationName: types.optional(types.string, ""),
		form: types.optional(
			types
				.model("Form", {
					name: types.optional(types.string, ""),
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => {
					const validate = () => {
						if (self.name === "") {
							self.hasError = true
							self.errorMessage = "This field is required"
						} else {
							self.hasError = false
						}
					}

					const setName = (name: string) => {
						self.name = name
					}

					return { validate, setName }
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			organizationId: string,
			currentOrganizationName: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Organization:Rename Started")
			updateCallback = callback

			self.organizationId = organizationId
			self.currentOrganizationName = currentOrganizationName
			self.isOpen = true
			self.form.name = currentOrganizationName
			self.form.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const renameOrganization = flow(function* () {
			if (!self.organizationId) {
				return
			}

			self.form.validate()

			if (self.form.hasError) {
				return false
			}

			try {
				self.isCreating = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.patchOrganization(
						{
							name: self.form.name,
						},
						{
							queries: { organization_id: self.organizationId },
						},
					),
				])

				Fathom.trackEvent("Organization:Rename Success")

				try {
					yield updateCallback()
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isCreating = false

				return true
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, renameOrganization }
	})
