import * as Fathom from "fathom-client"
import { Observer, observer } from "mobx-react"
import type React from "preact/compat"
import {
	BookOpen,
	Box,
	Cloud,
	ExternalLink,
	GitHub,
	LogOut,
	Mail,
	Plus,
	RefreshCw,
	Settings,
	Slack,
	User,
	UserPlus,
} from "react-feather"
import {
	Button,
	Divider,
	DropdownMenu,
	Icon,
	Link,
	Skeleton,
	Text,
	View,
} from "reshaped"

import { appStore, featureFlagNotificationsCenter, isProduction } from "#app"
import { NotificationCenter } from "#components/NotificationCenter"
import { getLinkProps, router, useAllRoutes } from "#router"

import { useEffect, useRef, useState } from "preact/hooks"
import { dictionary, track } from "#analytics/eventManager.ts"
import { api } from "#api"
import { modalsStore } from "#modals"
import Organization from "../../../public/icons/organization.svg?react"
import { NavigationSectionsType } from "../../enums"
import { organizationsPageStore } from "../../pages/Organization/page.model"
import Logo from "./logo.svg?react"
import css from "./navigation.module.css"

enum MenuSections {
	profile = "Profile",
	documentation = "Documentation",
	joinSlack = "Join Slack",
	sendFeedback = "Send Feedback",
	cookiePreferences = "Cookie Preferences",
	privacyPolicy = "Privacy Policy",
	resetOnboarding = "Reset Onboarding",
	logout = "Logout",
}

export const Navigation = observer(() => {
	const route = useAllRoutes()
	const createRef = useRef<HTMLDivElement | null>()
	const pipelinesRef = useRef<HTMLDivElement | null>()
	const spacesRef = useRef<HTMLDivElement | null>()
	const [activeLink, setActiveLink] = useState<string | null>(null)
	const [underlineStyle, setUnderlineStyle] = useState({})

	useEffect(() => {
		if (
			route?.name.startsWith("Onboarding") ||
			route?.name.startsWith("PipelinesCreate")
		) {
			setActiveLink(NavigationSectionsType.Create)
		} else if (
			route?.name.startsWith("PipelinesList") ||
			route?.name.startsWith("PipelinesDetail") ||
			route?.name.startsWith("DemoPipelinesDetail")
		) {
			setActiveLink(NavigationSectionsType.Pipelines)
		} else if (route?.name.startsWith("Spaces")) {
			setActiveLink(NavigationSectionsType.Spaces)
		} else {
			setActiveLink(null)
		}
	}, [route])

	useEffect(() => {
		switch (activeLink) {
			case NavigationSectionsType.Create: {
				setUnderlineStyle({
					left: createRef.current?.offsetLeft,
					width: createRef.current?.offsetWidth,
				})
				break
			}
			case NavigationSectionsType.Pipelines: {
				setUnderlineStyle({
					left: pipelinesRef.current?.offsetLeft,
					width: pipelinesRef.current?.offsetWidth,
				})
				break
			}
			case NavigationSectionsType.Spaces: {
				setUnderlineStyle({
					left: spacesRef.current?.offsetLeft,
					width: spacesRef.current?.offsetWidth,
				})
				break
			}
			default: {
				setUnderlineStyle({})
			}
		}
	}, [activeLink])

	function handleTracking(section: string) {
		switch (section) {
			case MenuSections.documentation:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts
							?.documentation ?? "Documentation",
				})
				break
			case MenuSections.joinSlack:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts?.joinSlack ??
						"Join Slack",
				})
				break
			case MenuSections.sendFeedback:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts
							?.sendFeedback ?? "Send Feedback",
				})
				break
			case MenuSections.cookiePreferences:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts
							?.cookiePreferences ?? "Cookie Preferences",
				})
				break
			case MenuSections.privacyPolicy:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts
							?.privacyPolicy ?? "Privacy Policy",
				})
				break
			case MenuSections.resetOnboarding:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts
							?.resetOnboarding ?? "Reset Onboarding",
				})
				break
			case MenuSections.logout:
				track({
					event: dictionary.profileInteraction,
					context:
						dictionary?.profileInteraction?.contexts?.logout ??
						"Logout",
				})
				break
		}
	}

	return (
		<View align="center" direction="row">
			<View
				gap={4}
				align="center"
				direction="row"
				divided
				paddingStart={3}
			>
				<View.Item>
					<Link
						href={router.Home()}
						onClick={getLinkProps(router.Home()).onClick}
						attributes={{ ariaLabel: "Go to Home" }}
					>
						<Logo
							height="18px"
							style={{
								color: "var(--rs-color-foreground-primary)",
							}}
						/>
					</Link>
				</View.Item>

				<View.Item gapBefore={3}>
					<View className={css.container} gap={2} direction="row">
						<Button
							color="media"
							variant="ghost"
							as="a"
							ref={createRef}
							href={router.Onboarding()}
							onClick={getLinkProps(router.Onboarding()).onClick}
						>
							<Icon size={4} svg={<Plus />} />
							Create
						</Button>
						<Button
							color="media"
							variant="ghost"
							as="a"
							ref={pipelinesRef}
							href={router.PipelinesList()}
							onClick={
								getLinkProps(
									router.PipelinesList({ page: "1" }),
								).onClick
							}
						>
							<Icon size={4} svg={<Box />} />
							Pipelines
						</Button>
						<Button
							color="media"
							variant="ghost"
							as="a"
							ref={spacesRef}
							href={router.Spaces()}
							onClick={
								getLinkProps(router.Spaces({ page: "1" }))
									.onClick
							}
						>
							<Icon size={4} svg={<Cloud />} />
							Spaces
						</Button>
						<Button
							color="media"
							variant="ghost"
							as="a"
							attributes={{ target: "_blank" }}
							href="https://github.com/glassflow/glassflow-examples/tree/main/examples"
							onClick={() => {
								track({
									event: dictionary.pageView,
									context:
										dictionary?.pageView?.contexts
											?.examples ?? "Examples",
								})
							}}
						>
							<Icon size={4} svg={<GitHub />} />
							Examples
						</Button>
						<Divider
							className={css.line}
							attributes={{ style: underlineStyle }}
						/>
					</View>
				</View.Item>
			</View>

			<View direction="row" gap={2} justify="end" grow>
				{featureFlagNotificationsCenter() ? (
					<NotificationCenter />
				) : null}
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => (
							<Button
								variant="ghost"
								color="neutral"
								attributes={{
									...attributes,
									ariaLabel: "User Menu",
								}}
								onClick={() => {
									track({
										event: dictionary.modalView,
										context:
											dictionary?.modalView?.contexts
												?.profile ?? "Profile",
									})
								}}
								icon={User}
							/>
						)}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								href={router.Profile()}
								onClick={getLinkProps(router.Profile()).onClick}
								selected={getLinkProps(router.Profile()).active}
								icon={User}
							>
								{MenuSections.profile}
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href="https://www.glassflow.dev/docs"
								icon={BookOpen}
								endSlot={<ExternalLink size={12} />}
								onClick={() => {
									handleTracking(MenuSections.documentation)
								}}
							>
								{MenuSections.documentation}
							</DropdownMenu.Item>
							{/* <DropdownMenu.Item
								as="a"
								href={router.Settings()}
								onClick={
									getLinkProps(router.Settings()).onClick
								}
								selected={
									getLinkProps(router.Settings()).active
								}
								icon={Settings}
							>
								Settings
							</DropdownMenu.Item> */}
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								href="https://join.slack.com/t/glassflowhub/shared_invite/zt-2g3s6nhci-bb8cXP9g9jAQ942gHP5tqg"
								attributes={{ target: "_blank" }}
								icon={Slack}
								endSlot={<ExternalLink size={12} />}
								onClick={() => {
									handleTracking(MenuSections.joinSlack)
								}}
							>
								{MenuSections.joinSlack}
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href={`mailto:help@glassflow.dev?subject=User Feedback | Web v${APP_VERSION}`}
								icon={Mail}
								onClick={() => {
									Fathom.trackEvent("Header:Send Feedback")
									handleTracking(MenuSections.sendFeedback)
								}}
								endSlot={<ExternalLink size={12} />}
							>
								{MenuSections.sendFeedback}
								<Text color="neutral-faded" variant="caption-2">
									help@glassflow.dev
								</Text>
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								onClick={() => {
									window._hsp = window._hsp || []
									window._hsp.push(["showBanner"])
									handleTracking(
										MenuSections.cookiePreferences,
									)
								}}
							>
								{MenuSections.cookiePreferences}
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								attributes={{ target: "_blank" }}
								href="https://glassflow.dev/privacy-policy"
								endSlot={<ExternalLink size={12} />}
								onClick={() => {
									handleTracking(MenuSections.privacyPolicy)
								}}
							>
								{MenuSections.privacyPolicy}
							</DropdownMenu.Item>
							{!isProduction() && (
								<DropdownMenu.Item
									onClick={() => {
										appStore.auth.isAuthenticated &&
											api.SaveUserExternalSettings({
												...appStore.auth.profile
													.external_settings,
												questions: false,
											})
										handleTracking(
											MenuSections.resetOnboarding,
										)
									}}
								>
									{MenuSections.resetOnboarding}
								</DropdownMenu.Item>
							)}
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								color="critical"
								onClick={() => {
									organizationsPageStore.deleteSelectedOrganization()
									appStore.logout()
									handleTracking(MenuSections.logout)
								}}
								icon={LogOut}
							>
								{MenuSections.logout}
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => {
							return (
								<Observer>
									{() => {
										let content: string | React.JSX.Element

										if (
											!organizationsPageStore.isFetchingOrganization
										) {
											content =
												organizationsPageStore
													.organization?.name || ""
										} else {
											content = (
												<Skeleton
													width={25}
													height={4}
												/>
											)
										}

										return (
											<View>
												<Button
													className={
														css.organizationButton
													}
													fullWidth
													variant="outline"
													rounded
													color="neutral"
													attributes={{
														...attributes,
														ariaLabel:
															"Organization Menu",
													}}
													icon={Organization}
												>
													{content}
												</Button>
											</View>
										)
									}}
								</Observer>
							)
						}}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								as="a"
								onClick={() => {
									modalsStore.inviteToOrganization.openModal(
										organizationsPageStore.organization
											?.id || "",
									)
								}}
								icon={UserPlus}
							>
								Invite Users
							</DropdownMenu.Item>
							<DropdownMenu.Item
								as="a"
								href={router.OrganizationPage()}
								onClick={
									getLinkProps(router.OrganizationPage())
										.onClick
								}
								selected={
									getLinkProps(router.OrganizationPage())
										.active
								}
								icon={Settings}
							>
								Organization Settings
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							{organizationsPageStore.organizations.length ===
								2 &&
								(() => {
									const anotherOrganization =
										organizationsPageStore.organizations.filter(
											(organization) =>
												organization.id !==
												organizationsPageStore.selectedOrganizationId,
										)[0]

									return (
										<DropdownMenu.Item
											onClick={() =>
												organizationsPageStore.saveSelectedOrganization(
													anotherOrganization.id,
												)
											}
											icon={RefreshCw}
										>
											Switch to {anotherOrganization.name}
										</DropdownMenu.Item>
									)
								})()}
							{organizationsPageStore.organizations.length >
								2 && (
								<DropdownMenu.SubMenu>
									<DropdownMenu.SubTrigger>
										<View
											direction="row"
											align="center"
											gap={2}
										>
											<Icon size={4} svg={RefreshCw} />{" "}
											Switch to
										</View>
									</DropdownMenu.SubTrigger>
									<DropdownMenu.Content>
										{organizationsPageStore.organizations.map(
											(organization) => (
												<DropdownMenu.Item
													key={organization.id}
													onClick={() =>
														organizationsPageStore.saveSelectedOrganization(
															organization.id,
														)
													}
												>
													{organization.name}
												</DropdownMenu.Item>
											),
										)}
									</DropdownMenu.Content>
								</DropdownMenu.SubMenu>
							)}
							<DropdownMenu.Item
								onClick={() => {
									modalsStore.createOrganization.openModal(
										() => {
											return organizationsPageStore.getOrganizationsPage(
												{
													page: 1,
													size: 9999,
													hideFetching: true,
												},
											)
										},
									)
								}}
								icon={Plus}
							>
								Create New Organization
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>
			</View>
		</View>
	)
})
