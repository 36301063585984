import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { Check, Edit2, MoreVertical, Trash, UserPlus } from "react-feather"
import {
	Button,
	Card,
	DropdownMenu,
	Icon,
	Skeleton,
	Table,
	Text,
	View,
	useToast,
} from "reshaped"
import { appStore } from "#app"
import { modalsStore } from "#modals"
import { getLinkProps, router } from "#router"
import DoorOpen from "../../../public/icons/doorOpen.svg?react"
import { pipelinesPageStore } from "../Pipelines/page.model"
import { spacesPageStore } from "../Spaces/page.model"
import { organizationsPageStore } from "./page.model"
import css from "./page.module.css"

const TextSkeleton = () => <Skeleton height={2} width={40} />

export const Organization = observer(() => {
	const toast = useToast()
	const store = organizationsPageStore
	const inviteToOrganizationModalStore = modalsStore.inviteToOrganization

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		store.getMembers()
		store.isAdmin && store.getPendingInvites()
	}, [store.organization?.id, store.isAdmin])

	const resendInvitation = async (email: string) => {
		try {
			const success =
				await inviteToOrganizationModalStore.inviteToOrganization(
					[{ email }],
					store.organization?.id || "",
				)
			if (success) {
				const id = toast.show({
					color: "neutral",
					timeout: 5000,
					icon: Check,
					title: "Invitation resent.",
					actionsSlot: (
						<Button
							variant="faded"
							color="inherit"
							onClick={() => toast.hide(id)}
						>
							Dismiss
						</Button>
					),
				})
			}
		} catch (error) {
			console.error("Resent Error:", error)
		}
	}

	const revokeInvitation = async (inviteId: string) => {
		try {
			const success = await store.revokeInvite(
				store.organization?.id || "",
				inviteId,
			)
			if (success) {
				const id = toast.show({
					color: "neutral",
					timeout: 5000,
					icon: Check,
					title: "Invitation revoked.",
					actionsSlot: (
						<Button
							variant="faded"
							color="inherit"
							onClick={() => toast.hide(id)}
						>
							Dismiss
						</Button>
					),
				})
			}
		} catch (error) {
			console.error("Revoke Error:", error)
		}
	}

	return (
		<View paddingTop={4} gap={2}>
			<View
				direction="row"
				justify="space-between"
				gap={5}
				paddingBottom={7}
			>
				<View paddingBottom={1}>
					<Text variant="featured-1" weight="bold">
						{store.organization?.name}
					</Text>
					<Text variant="body-1">
						{store.organization?.created_at ? (
							`Created on ${Intl.DateTimeFormat("en", {
								dateStyle: "long",
							}).format(
								Date.parse(store.organization?.created_at),
							)}`
						) : (
							<TextSkeleton />
						)}
					</Text>
				</View>
				<View direction="row" wrap={false} gap={3}>
					{store.isAdmin ? (
						<>
							<Button
								onClick={() => {
									modalsStore.renameOrganization.openModal(
										store.organization?.id || "",
										store.organization?.name || "",
										() => {
											return store.getOrganizationPage({
												id: store.selectedOrganizationId,
												hideFetching: true,
											})
										},
									)
								}}
								className={css.secondaryButton}
								icon={Edit2}
							>
								Rename Organization
							</Button>
							<Button
								onClick={() => {
									pipelinesPageStore.pipelines.length > 0
										? modalsStore.cannotDeleteOrganization.openModal()
										: modalsStore.deleteOrganization.openModal(
												store.organization?.id || "",
												store.organization?.name || "",
												() => {
													return store.getOrganizationPage(
														{
															id: store.selectedOrganizationId,
															hideFetching: true,
														},
													)
												},
											)
								}}
								className={css.secondaryButton}
								icon={Trash}
							>
								Delete Organization
							</Button>
							<Button
								className={css.actionButton}
								icon={UserPlus}
								onClick={() => {
									modalsStore.inviteToOrganization.openModal(
										store.organization?.id || "",
									)
								}}
							>
								Invite Users
							</Button>
						</>
					) : (
						<Button
							className={css.secondaryButton}
							icon={<Icon size={4} svg={<DoorOpen />} />}
							onClick={() =>
								appStore.auth.isAuthenticated &&
								modalsStore.leaveAsNonOwner.openModal(
									store.organization?.id || "",
									appStore.auth.profile.id,
									() => {
										return store.getOrganizationPage({
											id: store.selectedOrganizationId,
											hideFetching: true,
										})
									},
								)
							}
						>
							Leave Organization
						</Button>
					)}
				</View>
			</View>
			<View paddingBottom={9} gap={2}>
				<Text variant="featured-2" weight="medium">
					{store.members?.total_amount} active{" "}
					{store.members && store.members?.total_amount > 1
						? "members"
						: "member"}
				</Text>
				<Card elevated padding={0}>
					<Table>
						<Table.Row highlighted>
							<Table.Heading verticalAlign="center">
								Name
							</Table.Heading>
							<Table.Heading verticalAlign="center">
								Email
							</Table.Heading>
							<Table.Heading verticalAlign="end" />
						</Table.Row>
						{store.members?.items
							?.slice()
							.sort(
								(a, b) =>
									["admin", "member"].indexOf(a.role) -
									["admin", "member"].indexOf(b.role),
							)
							.map((member) => (
								<Table.Row key={member.id}>
									<Table.Cell verticalAlign="center">
										{member.name}{" "}
										{member.role === "admin" && (
											<span style={{ color: "#858585" }}>
												(owner)
											</span>
										)}
									</Table.Cell>
									<Table.Cell verticalAlign="center">
										{member.email}
									</Table.Cell>
									<Table.Cell align="end">
										{store.isAdmin && (
											<DropdownMenu position="bottom-end">
												<DropdownMenu.Trigger>
													{(attributes) => (
														<Button
															variant="ghost"
															color="neutral"
															attributes={{
																...attributes,
																ariaLabel:
																	"Member Actions",
															}}
															icon={MoreVertical}
														/>
													)}
												</DropdownMenu.Trigger>
												<DropdownMenu.Content>
													<DropdownMenu.Section>
														<DropdownMenu.Item
															onClick={
																getLinkProps(
																	router.OrganizationMemberPage(
																		{
																			memberId:
																				member.id,
																		},
																	),
																).onClick
															}
														>
															User info
														</DropdownMenu.Item>

														{appStore.auth
															.isAuthenticated &&
															member.id !==
																appStore.auth
																	.profile
																	.id &&
															store.isAdmin && (
																<>
																	<DropdownMenu.Item
																		onClick={() => {
																			modalsStore.transferOwnership.openModal(
																				store
																					.organization
																					?.id ||
																					"",
																				member.id,
																				member.name,
																				() => {
																					return store.getOrganizationPage(
																						{
																							id: store.selectedOrganizationId,
																							hideFetching: true,
																						},
																					)
																				},
																			)
																		}}
																	>
																		Transfer
																		ownership
																	</DropdownMenu.Item>
																	<DropdownMenu.Item
																		onClick={() => {
																			modalsStore.deleteMember.openModal(
																				store
																					.organization
																					?.id ||
																					"",
																				member.id,
																				member.name,
																				() => {
																					return store.getOrganizationPage(
																						{
																							id: store.selectedOrganizationId,
																							hideFetching: true,
																						},
																					)
																				},
																			)
																		}}
																	>
																		Remove
																		user
																	</DropdownMenu.Item>
																</>
															)}
													</DropdownMenu.Section>
												</DropdownMenu.Content>
											</DropdownMenu>
										)}
									</Table.Cell>
								</Table.Row>
							))}
					</Table>
				</Card>
			</View>
			{store.invites && store.invites?.total_amount > 0 && (
				<>
					<Text variant="featured-2" weight="medium">
						{store.invites?.total_amount} pending{" "}
						{store.invites.total_amount > 1
							? "invitations"
							: "invitation"}
					</Text>
					<Card elevated padding={0}>
						<Table>
							<Table.Row highlighted>
								<Table.Heading verticalAlign="center">
									Email
								</Table.Heading>
								<Table.Heading verticalAlign="end" />
							</Table.Row>
							{store.invites?.items.map((invite) => (
								<Table.Row key={invite.id}>
									<Table.Cell verticalAlign="center">
										{invite.email}
									</Table.Cell>
									<Table.Cell align="end">
										<DropdownMenu position="bottom-end">
											<DropdownMenu.Trigger>
												{(attributes) => (
													<Button
														variant="ghost"
														color="neutral"
														attributes={{
															...attributes,
															ariaLabel:
																"Space Actions",
														}}
														icon={MoreVertical}
													/>
												)}
											</DropdownMenu.Trigger>
											<DropdownMenu.Content>
												<DropdownMenu.Section>
													<DropdownMenu.Item
														onClick={() =>
															resendInvitation(
																invite.email,
															)
														}
													>
														Resend
													</DropdownMenu.Item>
													<DropdownMenu.Item
														onClick={() =>
															revokeInvitation(
																invite.id,
															)
														}
													>
														Revoke invitation
													</DropdownMenu.Item>
												</DropdownMenu.Section>
											</DropdownMenu.Content>
										</DropdownMenu>
									</Table.Cell>
								</Table.Row>
							))}
						</Table>
					</Card>
				</>
			)}
		</View>
	)
})
